import Layout from "./Layout";
import Events from "./Events";

const LandingPage = () => {
  return (
    <>
      <Layout />
      <Events />
    </>
  );
};

export default LandingPage;
