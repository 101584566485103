const PurchaseSummary = (props) => {
  return (
    <div
      className={`rounded justify-around border border-whiteSmoke shadow-lg px-4 py-3 h-80 sm:py-8 sm:px-6 sm:h-auto ${props.className}`}
    >
      <h3 className="border-yellow border-b-4 inline">Resumo da compra</h3>
      <div className="flex flex-col justify-evenly h-full ">
        {props.purchaseSummary.map((element) => (
          <p
            className="flex justify-between text-sm sm:text-lg "
            key={element.title}
          >
            <span>{element.title}</span>
            <span className="font-bold text-right text-sm sm:text-lg">
              {element.option}
            </span>
          </p>
        ))}
        <hr className="text-grey" />
        <p className="flex justify-between text-sm sm:text-xl font-bold items-center pb-8">
          <span className="text-xl md:text-h3">Total</span>
          <span className="text-yellow font-extrabold text-h3 md:text-5xl leading-9 md:leading-13">
            <span className="text-base md:text-xl font-bold">R$ </span>
            {props.formData.value.slice(0, -3)}
            <span className="text-base md:text-xl align-top">
              {props.formData.value.slice(-3)}
            </span>
            <span className="text-base md:text-xl font-bold">
              / {props.period}
            </span>
          </span>
        </p>
      </div>
    </div>
  );
};

export default PurchaseSummary;
