import Information from "../../../assets/images/svg/Information";

const MonthlyInfo = (props) => {
  return (
    <div
      key={props.subscription.title}
      className="px-4 md:px-6 py-4 lg:px-12 xl:px-24 "
    >
      <div className="flex justify-center md:justify-start">
        <h3 className="border-yellow border-b-4 inline">
          {props.subscription.title}
        </h3>
      </div>

      <p className="text-justify md:text-left my-6  font-semibold">
        {props.subscription.description}
      </p>
      {props.subscription.steps.map((step, i) => (
        <div
          key={step}
          className="h-16 xl:h-14 sm:h-20 flex items-center justify-center sm:items-center "
        >
          <h3 className="sm:mr-3">{i + 1}</h3>
          <div className="w-3/4 sm:w-full mx-auto sm:mx-0 text-left flex items-center">
            {step}{" "}
            {i === props.subscription.steps.length - 1 && (
              <div className="has-tooltip ml-2">
                <span className="tooltip shadow-lg p-2 bg-yellow rounded-md text-base">
                  Por enquanto, nossas assinaturas só podem ser feitas com{" "}
                  <span className="font-bold">cartão de crédito</span>.
                </span>
                <Information />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MonthlyInfo;
