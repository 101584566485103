import CustomNavLink from "../Header/CustomNavLink";

const Navbar = (props) => {
  return (
    <nav className="grid">
      <ul className="flex justify-evenly font-semibold">
        {props.linksNavbar.map((link) => {
          return (
            <CustomNavLink
              key={link.key}
              href={link.href || ""}
              to={link.to || ""}
            >
              {link.key}
            </CustomNavLink>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
