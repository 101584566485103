import Container from "../components/Layout/Container/Container";
import EventsInfos from "./EventsInfos";

const Events = () => {
  return (
    <div id="image-background" className="bg-greytwo">
      <Container>
        <div className="grid lg:grid-cols-2">
          <div className="py-6 grid justify-start">
            <h2 className="mb-10 font-display md:pt-4 text-6xl font-light">
              Encontre a gente na feira
            </h2>
            <EventsInfos />
          </div>
          <div></div>
        </div>
      </Container>
    </div>
  );
};

export default Events;
