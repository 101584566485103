import { sendGAEvent } from "../../helpers/SendGAEvent";
const LinkMenu = (props) => {
  return (
    <div className="grid">
      <ul className="flex justify-end mb-2">
        {props.linksMenu.map((link) => {
            let propEvent = {
              category: link.title,
              action: link.eventGA,
              label: link.href
            }
          return (
            <li
              key={link.title}
              className={`text-center rounded-xl rounded-tr-none ml-6 ${link.background}`}
            >
              <a href={link.href} target={link.target} rel="noreferrer"
               className="block w-full h-full hover:underline px-4 py-2"
               onClick={sendGAEvent.bind(this, propEvent)}>
                <span className="text-xs">{link.subtitle}</span>
                <br />
                <span className="font-bold">{link.title}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LinkMenu;
