export const leaf = [
  {
    id: "cfl1",
    category: "Folhosas",
    products: [
      {
        id: "fl1",
        type: "Couve",
        options: [
          "Couve Toscana (1un, aprox. 80g)",
          "Couve Mineira (1un, aprox. 150g)",
          "Couve Kale (1un, aprox. 150g)",
        ],
      },
      {
        id: "fl2",
        type: "Espinafre",
        options: ["Espinafre (1un, aprox. 150g)"],
      },
      {
        id: "fl3",
        type: "Alface",
        options: [
          "Alface Crespa Verde (1un, aprox. 120g)",
          "Alface Crespa Roxa (1un, aprox. 120g)",
          "Alface Lisa (1un, aprox. 120g)",
          "Alface Romana (1un, aprox. 120g)",
        ],
      },
      {
        id: "fl4",
        type: "Almeirão",
        options: ["Almeirão Roxo (1un, aprox. 80g)"],
      },
      {
        id: "fl5",
        type: "Chicória",
        options: [
          "Chicória Crespa (1un, aprox. 150g)",
          "Chicória Lisa (1un, aprox. 150g)",
        ],
      },
      {
        id: "fl6",
        type: "Rúcula",
        options: ["Rúcula (1un, aprox. 80g)"],
      },
    ],
  },
];
