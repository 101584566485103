import { HashLink } from "react-router-hash-link";

const CustomLink = (props) => {
  const className = "hover:underline flex items-center";

  if (props.href) {
    return (
      <a
        className={className}
        rel="noopener noreferrer"
        target="_blank"
        href={props.href}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
          />
        </svg>
        {props.children}
      </a>
    );
  } else {
    return (
      <HashLink smooth to={props.to} className={className}>
        {props.children}
      </HashLink>
    );
  }
};

export default CustomLink;
