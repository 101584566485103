import React, { useState } from "react";
import LinkMenu from "../LinkMenu/LinkMenu";
import Logo from "../Logo/Logo";
import Navbar from "../Navbar/Navbar";
import Container from "../Layout/Container/Container";
import HeaderMobile from "./HeaderMobile";

const Header = () => {
  const [show, setShow] = useState(false);

  const handleShowMenu = () => {
    setShow(!show);
  };

  return (
    <>
      <header
        className={`border-b fixed top-0 right-0 left-0 z-50 bg-white border-grey p-4 ${
          !show ? "shadow-md" : ""
        }`}
      >
        <Container>
          <div className="grid grid-cols-12 lg">
            <div className="col-span-4 lg:col-span-3 xl:col-span-4  my-auto self-start">
              <Logo />
            </div>
            <div className="hidden lg:block lg:col-span-4 xl:col-span-4 my-auto">
              <Navbar linksNavbar={linksNavbar} />
            </div>
            <div className="hidden lg:block lg:col-span-5 xl:col-span-4 xl:m-0">
              <LinkMenu linksMenu={linksMenu} />
            </div>
            <div className={`m-3 lg:hidden flex col-span-8 justify-end`}>
              <button
                onClick={handleShowMenu}
                className="outline-none mobile-menu-button"
              >
                <svg
                  className="w-6 h-6 text-grey-500"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
          </div>
        </Container>
      </header>
      {show && (
        <>
          <HeaderMobile
            linksNavbar={linksNavbar}
            linksMenu={linksMenu}
            handleShowMenu={handleShowMenu}
          />
        </>
      )}
    </>
  );
};

const linksMenu = [
  // {
  //   title: "Assina Junta",
  //   subtitle: "entrega recorrente",
  //   background: "bg-grey",
  //   href: "/assinatura",
  //   target: "_self",
  //   eventGA: "view_item"
  // },
  // {
  //   title: "Pede Junta",
  //   subtitle: "entrega rápida",
  //   background: "bg-grey",
  //   href: "https://pede.juntalocal.com/",
  //   target: "_blank",
  //   eventGA: "view_delivery"
  // }
  // {
  //   title: "Sacola da Junta",
  //   subtitle: "feira online",
  //   background: "bg-yellow",
  //   href: "https://sacolavirtual.com/",
  //   target: "_blank",
  //   eventGA: "view_sacola_virtual"
  // },
];

const linksNavbar = [
  { key: "Sobre Nós", to: "/assinatura#sobre-nos" },
  { key: "Produtores", href: "https://www.juntalocal.com/produtores" },
  { key: "Bairros e Entrega", to: "/bairros-e-entrega" },
];

export default Header;
