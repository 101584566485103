import TabuaPrincipal from "../assets/images/monthly/tabua-principal.webp";
import Cafe from "../assets/images/monthly/cafe.webp";
import DeixaComAJunta from "../assets/images/monthly/deixa-com-a-junta.jpg";
import TabuaTaca from "../assets/images/monthly/tabua-e-taca.webp";
import Torrefacao from "../assets/images/monthly/torrefacao.png";

export const monthlyData = [
  {
    id: "m3",
    category: "Descobertas Mensais",
    complement: true,
    vegan: false,
    available: false,
    title: "Tábua e Taça",
    productQuantity: "de 5 a 8 produtos surpresa",
    shortDescription:
      "Caixa com uma seleção de queijos, charcutaria artesanal, fermentados e destilados brasileiros.",
    description: (
      <>
        O melhor da charcutaria, salumeria e queijaria artesanal e local, sempre
        escoltado por um bom fermentado. Reunimos alguns dos mais incríveis
        produtores nestas categorias. Prepare sua tábua e taças e chame toda a
        turma! A caixa contém de 5 a 8 produtos surpresa.
        <br />
        <br />
        Opções vegetarianas e veganas disponíveis. Informe sua preferência no
        campo de Observação ao fechar o pedido.
        <br />
        <br />
        As entregas são na primeira ou terceira semana do mês, sempre nas
        quartas ou sábados. Pedidos realizados até o dia 15 serão entregues na
        terceira semana do mês. Já pedidos entre o dia 15 e o fim do mês serão
        entregues na primeira semana do mês seguinte. Qualquer alteração sobre a
        data de entrega, informe no campo de Observação ao fechar o pedido.
      </>
    ),
    href: "/tabua-e-taca",
    gallery: [
      { type: "object-cover", name: TabuaPrincipal },
      { imgProductDetail: TabuaTaca },
    ],
    buttons: false,
    productsBySize: [
      { id: 0, title: "", subtitle: "", items: [], prices: [] },
      { id: 1, title: "", subtitle: "", items: [], prices: [] },
      {
        id: 2,
        title: "",
        items: [],
        // prices: [null, null, { id: "tabua-e-taca", price: "259,90" }],
        prices: [null, null, { id: "tabua-e-taca", price: "" }],
      },
    ],
  },
  {
    id: "m1",
    category: "Descobertas Mensais",
    complement: true,
    vegan: false,
    available: false,
    title: "Do Pé à Xícara",
    productQuantity: "2 a 3 cafés surpresas",
    shortDescription:
      "Mergulhe no mundo dos cafés especiais brasileiros com essa caixa de descoberta mensal com indicações minuciosamente selecionadas (e por vezes torradas!) pela nossa guia de xícara, Juliana Ganan (Tocaya - Minas Gerais).",
    description: (
      <>
        Mergulhe no mundo dos cafés especiais brasileiros com essa caixa de
        descoberta mensal com indicações minuciosamente selecionadas (e por
        vezes torradas!) pela nossa guia de xícara Juliana Ganan (Tocaya - Minas
        Gerais). A caixa Do Pé à Xícara é uma oportunidade de aprender sobre
        esta cadeia e explorar as nuances sensoriais de excelentes cafés pelas
        mãos de uma das maiores especialistas no assunto. Prepara o moedor e o
        caderninho e vamos lá! A caixa contém de 2 a 3 cafés distintos, com peso
        total variando entre 300g e 500g.
        <br />
        <br />
        Por padrão, todos os cafés são enviados em grãos. Caso não tenha moedor,
        coloque a Observação “Moído” no campo de Observação ao fechar o pedido.
        <br />
        <br />
        As entregas são realizadas na primeira ou terceira semana do mês, sempre
        nas quartas ou sábados. Pedidos realizados até o dia 15 serão entregues
        na terceira semana do mês. Já pedidos entre o dia 15 e o fim do mês
        serão entregues na primeira semana do mês seguinte.Qualquer alteração
        sobre a data de entrega, informe no campo de Observação ao fechar o
        pedido.
      </>
    ),
    href: "/selecao-vegana",
    gallery: [
      { type: "object-cover", name: Cafe },
      { imgProductDetail: Torrefacao },
    ],
    buttons: false,
    productsBySize: [
      { id: 0, title: "", subtitle: "", items: [], prices: [] },
      { id: 1, title: "", subtitle: "", items: [], prices: [] },
      {
        id: 2,
        title: "",

        items: [],
        // prices: [null, null, { id: "do-pe-a-xicara", price: "129,90" }],
        prices: [null, null, { id: "do-pe-a-xicara", price: "" }],
      },
    ],
  },
  {
    id: "m2",
    category: "Descobertas Mensais",
    complement: true,
    vegan: false,
    available: false,
    title: "Deixa com a Junta",
    productQuantity: "de 5 a 8 produtos surpresa",
    shortDescription:
      "Caixa com a seleção do melhor da nossa comunidade, com novidades e destaques da Feira e da Sacola. Só confia!",
    description:
      "Coloque a venda sobre os olhos e deixe a curadoria da Junta guiar seu paladar. Nesta caixa iremos destacar novidades e trazer nossos produtos favoritos para proporcionar experiências gastronômicas incríveis.",
    href: "/confianca-local",
    gallery: [
      { type: "object-cover", name: DeixaComAJunta },
      { imgProductDetail: "" },
    ],
    buttons: false,
    productsBySize: [
      { id: 0, title: "", subtitle: "", items: [], prices: [] },
      { id: 1, title: "", subtitle: "", items: [], prices: [] },
      {
        id: 2,
        title: "",
        items: [],
        prices: [null, null, { id: "29", price: "" }],
      },
    ],
  },
];

export default monthlyData;
