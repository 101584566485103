import { useState } from "react";
import { useLocation } from "react-router-dom";
import { boxSubscriptionData } from "../data/box-subscription-data";
import { monthlyData } from "../data/monthly-data";
import Carousel from "../components/Carousel/Carousel";
import Options from "../components/BoxContent/Options";
import CategoryModal from "../components/BoxContent/CategoryModal";
import Section from "../components/Layout/Section/Section";
import ProductCard from "../components/ProductCard/ProductCard";
import ProductSwiper from "../components/ProductSwiper/ProductSwiper";
import Breadcrumb from "../components/Breadcrumb";
import { productTypes } from "../data/product-types-data";


const Box = (props) => {
  const [availableProducts, setAvailableProducts] = useState({});
  const [toggleModal, setToggleModal] = useState(false);

  let setButton = 2;
  if (props.box.buttons) setButton = 0;

  //chosen size
  const [size, setSize] = useState(setButton);
  const [frequency, setFrequency] = useState(setButton);

  const setSizeHandler = (value) => {
    setSize(value);
  };

  const setFrequencyHandler = (value) => {
    setFrequency(value);
  };

  //render planes other than pathname
  let otherPlans = [];
  const pathname = useLocation().pathname;
  boxSubscriptionData.forEach((data) => {
    if (data.href !== pathname) {
      otherPlans.push(data);
    }
  });
  monthlyData.forEach((data) => {
    if (data.href !== pathname) {
      otherPlans.push(data);
    }
  });
  otherPlans = otherPlans.sort(() => Math.random() - 0.5).slice(0, 3);

  const productTypesHandler = (e) => {
    const categoryIndex = e.target.value;

    setAvailableProducts(productTypes[categoryIndex]);
    setToggleModal(true);
  };

  const closeModal = () => {
    setToggleModal(false);
  };

  return (
    <>
      <CategoryModal
        modalData={availableProducts}
        isModalOpen={toggleModal}
        closeModal={closeModal}
      />
      <Section border={true}>
        <Breadcrumb
          title={props.box.title}
          category={props.box.category}
          hashpath={props.hash}
        />
        <div className="lg:grid lg:grid-cols-2 lg:gap-12">
          <Carousel
            type="manual"
            className="rounded"
            gallery={props.box.gallery}
          />
          <Options
            box={props.box}
            stateSizeHandler={setSizeHandler}
            stateFrequencyHandler={setFrequencyHandler}
            size={size}
            frequency={frequency}
          />
        </div>
      </Section>
      <Section border={true}>
        <div className="grid lg:grid-cols-2 lg:gap-12 lg:border-0 pb-8 sm:pb-0 lg:pt-10">
          <div className="py-8 flex flex-col">
            <div className="flex">
              <div className="hidden lg:block w-4 h-full mt-2 border-l-4 border-yellow mr-4"></div>
              <div>
                <h3 className="text-center sm:text-left">
                  Detalhes do Produto
                </h3>
                <p role="note" className="mt-4 mb-8 text-justify">
                  {props.box.description}
                </p>
              </div>
            </div>
            <div className="lg:ml-4">
              {(props.box.productsBySize[0].items.length > 0 && props.box.productsBySize[0].subtitle.length > 0) &&
                <>
                  <h3 className="text-center sm:text-left ">
                    O que pode vir na caixa?
                  </h3>
                  <p className="mt-4 text-justify">
                    Clique nas categorias abaixo para ver informações sobre produtos
                    e quantidades que você poderá receber.
                  </p>

                  <div className="grid grid-cols-2 sm:grid-cols-5 gap-6 mt-4">
                    {productTypes.map((type, index) => {
                      return (
                        <button
                          key={type.id}
                          className="underline font-bold mr-5 sm:mr-0 hover:text-yellow transition duration-150 sm:text-left"
                          onClick={productTypesHandler}
                          value={index}
                        >
                          {type.category}
                        </button>
                      );
                    })}
                  </div>
                </>
              }
              {(props.box.title=== "Complemento de Laticínios" ) &&
                <>
                  <h3 className="text-center sm:text-left ">
                    O que pode vir na caixa?
                  </h3>
                  <p className="mt-4 text-justify">
                    Clique nas categorias abaixo para ver informações sobre produtos
                    e quantidades que você poderá receber.
                  </p>

                  <div className="grid grid-cols-2 sm:grid-cols-5 gap-6 mt-4">
                    {productTypes.map((type, index) => {
                      if (type.category === "Laticínios"){
                        return(
                          <button
                          key={type.id}
                          className="underline font-bold mr-5 sm:mr-0 hover:text-yellow transition duration-150 sm:text-left"
                          onClick={productTypesHandler}
                          value={index}
                          >
                          {type.category}
                        </button>
                      );
                    }
                    })}
                  </div>
                </>
              }
            </div>
          </div>

          {props.box.productsBySize[0].items.length === 0 &&
            <img className="object-cover rounded-xl" src={props.box.imgProductDetail} alt="" />
          }
          {props.box.productsBySize[0].items.length > 0 &&
            <div className="grid grid-cols-2 justify-items-center md:flex justify-around py-8 px-2 border border-grey rounded-xl">
              {props.box.productsBySize.map((box) => {
                return (
                  <div key={box.id}>
                    <span className="text-2xl font-extrabold">{box.title}</span>
                    <h5
                      id="h5.box-subtitle"
                      className="text-base sm:text-xl font-bold my-7 box-subtitle"
                    >
                      {box.subtitle}
                    </h5>

                    <ul className="border-l-4 border-yellow pl-2 md:pl-4">
                      {box.items.map((item) => (
                        <li className="text-base sm:text-lg " key={item}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>
          }
        </div>
      </Section>
      <div className="md:mb-8 pl-2 md:pl-4 text-center sm:text-left container mx-auto mt-10">
        <h3 className="border-b-4 border-yellow inline ">
          Recomendações para você
        </h3>
      </div>
      <div className="xl:hidden">
        <div className="hidden xl:grid sm:grid-cols-2 xl:grid-cols-3 gap-3 xl:gap-7 sm:pt-4">
          {otherPlans.map((box) => (
            <ProductCard box={box} key={box.title} />
          ))}
        </div>
        <ProductSwiper data={otherPlans} />
      </div>
      <div className="hidden xl:block">
        <Section id="caixas-da-junta" pt={false}>
          <div className="hidden xl:grid sm:grid-cols-2 xl:grid-cols-3 gap-3 xl:gap-7 sm:pt-4">
            {otherPlans.map((box) => (
              <ProductCard box={box} key={box.title} />
            ))}
          </div>
          <ProductSwiper data={otherPlans} />
        </Section>
      </div>
    </>
  );
};

export default Box;
