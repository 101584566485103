import Container from "../Container/Container";

const Section = (props) => {
  return (
    <section
      id={props.id}
      className={`${props.bgFullWidth || ""} ${
        props.pt ? "py-5 sm:py-10" : "pb-5 sm:pb-10"
      } ${props.border ? "border-b border-grey md:pb-10" : ""} `}
    >
      <Container border={props.border}>
        <div className={`${props.bgFullWidth ? "py-5 sm:py-10" : ""}`}>
          <div
            className={`${
              props.bgRounded
                ? `${props.bgRounded} rounded shadow-md p-8 border border-whiteSmoke`
                : ""
            }`}
          >
            {props.children}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Section;
