/* eslint-disable react/jsx-no-target-blank */
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { customerActions } from "../store/customers";
import { purchaseActions, createSignature } from "../store/purchases";
import { selectedBoxHelper } from "../helpers/SelectedBoxHelper";
import { groupButtonData } from "../data/group-button-data";
import { formattedCurrentDate } from "../helpers/DateUtils";
import InputMask from "react-input-mask";
import InputCep from "../components/InputCep/InputCep";
import Container from "../components/Layout/Container/Container";
import PurchaseSummary from "../components/PaymentContent/PurchaseSummary/PurchaseSummary";
import YourSubscription from "../components/PaymentContent/YourSubscription/YourSubscription";
import YellowButton from "../components/Layout/YellowButton/YellowButton";
import { sendGAEvent } from "../helpers/SendGAEvent";
import { sendGTM } from "../helpers/SendGTM";

const frequencyButtons = groupButtonData[1];

const PaymentForm = () => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const purchase = useSelector((state) => state.purchase);

  const { boxId } = useParams();
  let boxHelper = selectedBoxHelper(boxId);
  let selectedBox = boxHelper.selectedBoxVar;
  let indexArr = boxHelper.indexArr;

  const [submitStatus, setSubmitStatus] = useState({
    text: "Concluir assinatura",
    disabled: true,
  });
  const [frequencyIndex, setFrequencyIndex] = useState(indexArr[1]);
  const [sizeIndex, setSizeIndex] = useState(indexArr[0]);
  const [validation, setValidation] = useState(false);
  const [cepResponse, setCepResponse] = useState({});

  const purchaseSummary = [
    { title: "Plano", option: selectedBox.title },
    {
      title: "Tamanho",
      option: `${customer.size}`,
    },
    {
      title: "Local para entrega",
      option: customer.deliveryAddress?.city
        ? `${customer.deliveryAddress?.street}, ${
            customer.deliveryAddress?.number &&
            customer.deliveryAddress?.number + ","
          } ${customer.deliveryAddress?.city}, ${customer.deliveryAddress?.cep}`
        : "",
    },
    { title: "Dia para receber", option: customer.day },
  ];

  const formDataHandler = (e) => {
    dispatch(
      customerActions.updateCustomer({
        [e.target.id]: e.target.value,
        registerDate: formattedCurrentDate(),
      })
    );
  };

  const checkboxHandler = (e) => {
    dispatch(
      customerActions.updateCustomer({
        [e.target.id]: e.target.checked,
      })
    );
  };

  const cepResponseHandler = (value) => {
    setCepResponse(value);
    dispatch(
      customerActions.updateCustomer({
        billingAddress: {
          ...customer.billingAddress,
          cep: value.cep,
          nbhd: value.bairro,
          street: value.logradouro,
          city: value.localidade,
        },
      })
    );
  };

  const formAddressHandler = (e) => {
    dispatch(
      customerActions.updateCustomer({
        billingAddress: {
          ...customer.billingAddress,
          [e.target.id]: e.target.value,
        },
      })
    );
  };

  const onClickSubscriptionHandler = (e) => {
    e.preventDefault();
    const propEvent = {
      category: "completed_purchase",
      action: "purchase",
      label: "subscriptionButton",
      id: customer.boxId,
      value: customer.value,
      name: customer.boxName,
    };
    sendGTM(propEvent, e);
    sendGAEvent(propEvent, e);

    setSubmitStatus({ text: "Processando...", disabled: true });
    dispatch(createSignature(customer));
  };

  useEffect(() => {
    const validateForm = () => {
      setValidation(
        customer.acceptTerms &&
          customer.receivingOption.length > 0 &&
          customer.cardNumber.replace(/[^0-9]/g, "").length === 16 &&
          customer.cardName.length > 0 &&
          customer.cardExpirationDate.replace(/[^0-9]/g, "").length === 4 &&
          customer.cvv.replace(/[^0-9]/g, "").length === 3 &&
          customer.cpf.replace(/[^0-9]/g, "").length === 11 &&
          (customer.sameAddress ||
            (customer.billingAddress?.city?.length > 0 &&
              customer.billingAddress?.number?.length > 0))
      );
    };

    validateForm();
  }, [customer]);

  useEffect(() => {
    setSubmitStatus({ ...submitStatus, disabled: !validation });
  }, [validation]);

  const history = useHistory();
  useEffect(() => {
    if (purchase.success) {
      dispatch(purchaseActions.updateMessage({ success: false, message: "" }));
      history.push(`/agradecimento`);
    }
    setSubmitStatus({
      text: "Concluir assinatura",
      disabled: purchase.success,
    });
  }, [purchase]);

  return (
    <Container>
      <div className="grid lg:grid-cols-2 gap-6 my-7 sm:my-11">
        <div className="flex flex-col sm:grid grid-rows-2 gap-6 ">
          <PurchaseSummary
            className="hidden sm:block"
            purchaseSummary={purchaseSummary}
            period={frequencyButtons.buttons[frequencyIndex].period}
            formData={customer}
          />
          <YourSubscription selectedBox={selectedBox} sizeIndex={sizeIndex} />
        </div>

        <div className="bg-whiteSmoke border border-grey rounded px-4 py-3 sm:py-8 sm:px-6">
          <h3>Concluir Pedido</h3>
          <form className="grid gap-7 my-4">
            <div className="grid grid-cols-2 gap-7 my-4">
              <div className="col-span-2">
                <input
                  type="checkbox"
                  id="returnable"
                  className="border border-grey"
                  onChange={checkboxHandler}
                  defaultChecked={customer.returnable}
                />
                <label className="pl-2" htmlFor="returnable">
                  Quero retornar sacolas ou embalagens de pedidos anteriores.
                </label>
              </div>

              <select
                name="option"
                id="receivingOption"
                className="border border-grey bg-white decoration-wh h-14 px-3 rounded-xl col-span-3"
                onChange={formDataHandler}
                required
                value={customer.receivingOption}
              >
                <option>Eu mesmo ou alguém na minha residência</option>
                <option>Entregar na portaria</option>
              </select>
            </div>

            <div className="grid sm:grid-cols-2 gap-4">
              <hr className="text-grey col-span-2" />
              <h3 className="col-span-2">Dados do cartão</h3>
              <p className="col-span-2">
                Por enquanto, pelo site nossas assinaturas só podem ser feitas
                com cartão de crédito.
              </p>
              <p className="col-span-2 mb-4" >
                Caso tenha vale alimentação (Alelo, Sodexo e VR) basta escolher
                a caixa de assinatura que mais combina com você e acessar{" "}
                <a
                  className="underline underline-offset-1 "
                  href="https://aceitamosva.juntalocal.com/assinatura"
                  target={"_blank"}
                >
                  aceitamosva.juntalocal.com/assinatura
                </a>
              </p>

              <InputMask
                autoComplete="off"
                id="cardNumber"
                className="border border-grey rounded-xl w-full px-3 h-14"
                type="tel"
                mask="9999 9999 9999 9999"
                placeholder="Nº do cartão*"
                onChange={formDataHandler}
                required
                value={customer.cardNumber}
              />

              <input
                autoComplete="off"
                id="cardName"
                className="border border-grey rounded-xl w-full px-3 h-14"
                type="text"
                placeholder="Nome do titular*"
                onChange={formDataHandler}
                required
                value={customer.cardName}
              />

              <InputMask
                autoComplete="off"
                id="cardExpirationDate"
                className="border border-grey rounded-xl w-full px-3 h-14"
                mask="99/99"
                type="tel"
                placeholder="Data de vencimento* (MM/AA)"
                onChange={formDataHandler}
                required
                value={customer.cardExpirationDate}
              />

              <InputMask
                autoComplete="off"
                id="cvv"
                className="border border-grey rounded-xl w-full px-3 h-14"
                mask="999"
                type="tel"
                placeholder="CVV*"
                onChange={formDataHandler}
                required
                value={customer.cvv}
              />
              <InputMask
                autoComplete="off"
                id="cpf"
                className="border border-grey rounded-xl w-full px-3 h-14  "
                mask="999.999.999-99"
                type="tel"
                placeholder="CPF*"
                onChange={formDataHandler}
                required
                value={customer.cpf}
              />
            </div>

            <div className="grid grid-cols-2 gap-7 ">
              <hr className="text-grey col-span-2" />
              <h3 className="col-span-2">Endereço de cobrança</h3>

              <div className="col-span-2">
                <input
                  type="checkbox"
                  id="sameAddress"
                  className="border border-grey"
                  checked={customer.sameAddress}
                  onChange={checkboxHandler}
                />
                <label className="pl-1" htmlFor="sameAddress">
                  {" "}
                  Usar o mesmo endereço de entrega
                </label>
              </div>

              <InputCep
                pClassName="flex items-center justify-center px-3 rounded-xl"
                cepResponseHandler={cepResponseHandler}
                onChange={formAddressHandler}
                disabled={customer.sameAddress}
                cep={customer.billingAddress.cep}
                delivery={false}
              />
            </div>

            <div className="grid sm:grid-cols-2 gap-4">
              <input
                className="bg-disabledGrey opacity-75 border border-grey h-14 px-3 rounded-xl"
                type="text"
                placeholder="Cidade*"
                required
                disabled
                aria-label="city-input"
                value={cepResponse.localidade || ""}
              />
              <input
                className=" bg-disabledGrey opacity-75 border border-grey h-14 px-3 rounded-xl"
                type="text"
                placeholder="Bairro*"
                required
                disabled
                value={cepResponse.bairro || ""}
              />
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-10 gap-3 ">
              <input
                className="bg-disabledGrey opacity-75 border border-grey col-span-2 sm:col-span-5 h-14 px-3 rounded-xl"
                type="text"
                placeholder="Endereço*"
                required
                disabled
                value={cepResponse.logradouro || ""}
              />
              <input
                autoComplete="off"
                id="number"
                className="border border-grey sm:col-span-2 h-14 px-3 rounded-xl"
                type="text"
                placeholder="Número*"
                value={customer.billingAddress.number}
                onChange={formAddressHandler}
                required
                disabled={customer.sameAddress}
              />
              <input
                autoComplete="off"
                id="complement"
                className="border border-grey sm:col-span-3 h-14 px-3 rounded-xl"
                type="text"
                placeholder="Complemento"
                onChange={formAddressHandler}
                disabled={customer.sameAddress}
                value={customer.billingAddress.complement}
              />
            </div>
            <div>
              <input
                type="checkbox"
                id="acceptTerms"
                className="border border-grey"
                checked={customer.acceptTerms}
                onChange={checkboxHandler}
              />
              <label className="pl-1" htmlFor="acceptTerms">
                {" "}
                Concordo com os{" "}
                <a
                  className="underline"
                  href="https://sobre.juntalocal.com/termos"
                  target="_blank"
                  rel="noreferrer"
                >
                  Termos de Uso
                </a>
              </label>
            </div>
            <YellowButton
              type="submit"
              disabled={!validation || submitStatus.disabled}
              onClick={onClickSubscriptionHandler}
            >
              {submitStatus.text}
            </YellowButton>
            {!purchase.success && purchase.message !== "" && (
              <p className={`text-sm font-normal italic text-red`}>
                {purchase.message}
              </p>
            )}
          </form>
        </div>
        <PurchaseSummary
          className="sm:hidden block"
          purchaseSummary={purchaseSummary}
          period={frequencyButtons.buttons[frequencyIndex].period}
          formData={customer}
        />
      </div>
    </Container>
  );
};

export default PaymentForm;
