const OptionButton = (props) => {
  let disabledStyle = "bg-grey cursor-not-allowed opacity-50";

  if (props.disabled) {
    disabledStyle = "bg-transparent hover:bg-lightYellow hover:border-lightYellow";
  }

  return (
    <button
      value={props.value}
      className={` border border-grey w-full font-bold flex justify-center items-center
      mx-auto py-2 px-4 rounded transition
      duration-500 ${
        props.clicked === props.value ? "bg-yellow border-none" : disabledStyle
      } ${props.button}`}
      onClick={props.onClick}
    >
      {props.title}
    </button>
  );
};

export default OptionButton;
