import BoxImg from "../../../assets/images/caixa1.png";
import HashButton from "../../Buttons/HashButton";

const TransparentBanner = () => {

  return (
    <div className="overflow-hidden relative">
      <div className="bg-yellow transform rotate-330 -z-10 w-full sm:w-1/2 h-3/5 sm:h-full rounded-bl-full rounded-tl-full rounded-br-3xl rounded-tr-3xl -right-20 -top-24 absolute"></div>
      <div className="text-center sm:text-left my-12 lg:mb-12 lg:mt-24 flex flex-col-reverse sm:grid grid-cols-2 container mx-auto">
        <div className="sm:pl-14 xl:pl-32">
          <h1 className="leading-none xl:leading-17 mb-8 font-display sm:max-w-sm xl:max-w-full">
            Seja assinante da
            <span className="text-yellow"> Junta Local</span>
          </h1>
          <p className="font-semibold mb-8 text-base xl:text-teste px-8 sm:px-0 sm:max-w-md xl:max-w-lg ">
            Ficou mais fácil chegar junto e receber comida boa,
            <br className="hidden lg:inline" /> local e justa. O melhor da nossa
            comunidade,
            <br className="hidden lg:inline" /> sempre direto do produtor.
          </p>
          <p className="font-semibold mb-8 text-base xl:text-lg px-8 sm:px-0 sm:max-w-md xl:max-w-lg ">Tem vale alimentação (Alelo, Sodexo e VR)? Chegou sua hora de conhecer o Assina Junta! </p>
          <HashButton to="/assinatura#caixas-da-junta">Conheça as assinaturas</HashButton>
        </div>
        <img className="object-cover" src={BoxImg} alt="cesta da Junta" />
      </div>
    </div>
  );
};
export default TransparentBanner;
