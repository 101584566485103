import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, { Pagination } from "swiper";
import ProductCard from "../ProductCard/ProductCard";

SwiperCore.use([Pagination]);

const ProductSwiper = (props) => {
  return (
    <>
      <div className="block sm:hidden">
        <Swiper slidesPerView={1.2} spaceBetween={10} centeredSlides={true}>
          {props.data.map((box) => (
            !box.vegan &&
            <SwiperSlide
              key={box.title}
              className="bg-white w-full rounded shadow-lg h-full my-8 active:bg-whiteSmoke hover:opacity-80 transition duration-300 border border-whiteSmoke"
            >
              <ProductCard box={box} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="hidden sm:block xl:hidden">
        <Swiper slidesPerView={2.2} spaceBetween={20}>
          {props.data.map((box) => (
            !box.vegan &&
            <SwiperSlide
              key={box.href}
              className=" bg-white w-full rounded shadow-xl h-full active:bg-whiteSmoke hover:opacity-80 transition duration-300 mb-8 border border-whiteSmoke"
            >
              <ProductCard box={box} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};
export default ProductSwiper;
