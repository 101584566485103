export const fruit = [
  {
    id: "cf1",
    category: "Frutas",
    products: [
      {
        id: "f1",
        type: "Banana",
        options: ["Banana Prata (1kg)"],
      },
      {
        id: "f2",
        type: "Maçã",
        options: ["Maçã (500g)"],
      },
      {
        id: "f3",
        type: "Melão",
        options: ["Melão (1un, aprox. 600g)"],
      },
      {
        id: "f4",
        type: "Uva",
        options: ["Uva Isabel (500g)"],
      },
      {
        id: "f5",
        type: "Morango",
        options: ["Morango (200g)"],
      },
      {
        id: "f6",
        type: "Manga",
        options: [
          "Manga Palmer (500g)",
          "Manga Tommy (500g)",
          "Manga Espada (500g)",
        ],
      },
      {
        id: "f7",
        type: "Mamão",
        options: ["Mamão Papaya (500g)", "Mamão Formosa (500g)"],
      },
      {
        id: "f8",
        type: "Coco",
        options: ["Coco seco (200g)"],
      },
      {
        id: "f9",
        type: "Laranja",
        options: ["Laranja Pera (500g), Laranja Bahia (500g)"],
      },
      {
        id: "f10",
        type: "Abacaxi",
        options: ["Abacaxi (1un, aprox. 600g)"],
      },
      {
        id: "f11",
        type: "Lichia",
        options: ["Lichia (200g)"],
      },
      {
        id: "f12",
        type: "Framboesa",
        options: ["Framboesa (150g)"],
      },
      {
        id: "f13",
        type: "Amora",
        options: ["Amora (150g)"],
      },
    ],
  },
];
