import TransparentBanner from "../components/HomeContent/TransparentBanner/TransparentBanner";
import Monthly from "../components/HomeContent/Monthly/Monthly";
import WhyChooseUs from "../components/HomeContent/WhyChooseUs/WhyChooseUs";
import SubscriptionsForYou from "../components/HomeContent/SubscriptionsForYou/SubscriptionsForYou";
import BoxSubscription from "../components/HomeContent/BoxSubscription/BoxSubscription";
import AboutTheJunta from "../components/HomeContent/AboutTheJunta/AboutTheJunta";
import { subsForYouData } from "../data/subsforyou-data";
import DescobertasMensais from "../assets/images/descobertas-mensais.webp";
import Caixa4 from "../assets/images/caixa-assinatura.webp";
import DeliveryInformation from "../components/BoxContent/DeliveryInformation";
import Testimonials from "../components/Testimonials/Testimonials";
import WhatsAppButton from "../components/Buttons/WhatsAppButton";

const Home = () => {
  return (
    <main>
      <TransparentBanner />
      <WhyChooseUs />
      <SubscriptionsForYou
        hashpath={"caixas-da-junta"}
        subscription={subsForYouData[0]}
        boxImage={Caixa4}
      />
      <BoxSubscription />
      <SubscriptionsForYou
        hashpath={"descobertas-mensais"}
        subscription={subsForYouData[1]}
        boxImage={DescobertasMensais}
        right={true}
      />
      <Monthly />
      <Testimonials/>
      <DeliveryInformation />
      <AboutTheJunta />
      <WhatsAppButton/>
    </main>
  );
};

export default Home;
