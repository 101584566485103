import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Caixa from "../assets/images/capa/Caixa.png";
import CaixaSuperior from "../assets/images/capa/CaixaSuperior.png";
import Garrafas from "../assets/images/capa/Garrafas.png";
import Card from "../assets/images/card.png";
import YellowButton from "../components/Layout/YellowButton/YellowButton";
import { getCurrentCycle } from "../store/cycles";

const Layout = () => {
  const dispatch = useDispatch();
  const cycles = useSelector((state) => state.cycle.currentCycles);

  useEffect(() => {
    dispatch(getCurrentCycle());
  }, []);

  return (
    <>
      <div className=" hidden md:flex">
        <div className="w-1/2 h-full flex flex-col 	">
          <section className="bg-greyone pb-16 xl:pb-4 flex w-full justify-end ">
            <div className="pt-12 w-4/5 pr-6 lg:pr-0 pl-4 md:pl-0 ">
              <div>
                <div className="mb-4">
                  <h1 className="text-7xl font-display font-light decoration-1 hover:underline leading-14">
                    <a
                      href="https://sacolavirtual.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Receba a Junta em casa
                    </a>
                  </h1>
                </div>

                <div className="py-5">
                  <p className="text-base inline">
                    Veja a disponibilidade completa de produtos. A feira da
                    nossa comunidade, online.
                  </p>
                  <br />
                  <br />
                  {cycles.map((cycle) => {
                    var deliveryDate = new Date(cycle.deliveryDate);
                    deliveryDate.setDate(deliveryDate.getDate() + 1);
                    return (
                      <a
                        key={cycle.deliveryDate}
                        href="https://sacolavirtual.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <YellowButton>
                          Entrega{" "}
                          {new Intl.DateTimeFormat("pt-BR", {
                            weekday: "long",
                            month: "2-digit",
                            day: "2-digit",
                          }).format(deliveryDate)}
                        </YellowButton>
                      </a>
                    );
                  })}
                </div>
                <div className=" w-9/12 ">
                  <img src={Caixa} alt="" />
                </div>
              </div>
            </div>
          </section>
          <section className=" w-full flex h-full  md:justify-end ">
            <div className=" pl-4 md:pl-0 w-4/5 my-8 flex">
              <div className=" w-100">
                <h2 className="font-display text-5xl xl:text-6xl font-light hover:underline  ">
                  <a
                    href=" https://benfeitoria.com/projeto/ajuntadosdecarteirinha"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Quer ser um ajuntado de carteirinha?
                  </a>
                </h2>
              </div>
              <div className="mt-1 h-full w-80">
                <img className="place-self-end" src={Card} alt="" />
              </div>
            </div>
          </section>
        </div>

        <div className="w-1/2 bg-greythree ">
          <section className="bg-greytwo pb-16 xl:pb-4 pt-16 xl:pt-4">
            <div className="pr-4 md:pr-0 w-4/5 pb-8">
              <div className="text-right">
                <div className="mb-24 md:mb-0">
                  <img src={CaixaSuperior} alt="" />
                  <p className="text-base inline">
                    Entregas semanais, quinzenais e mensais
                  </p>
                  <br />
                  <p className="text-base inline ">
                    para você não se preocupar
                  </p>
                  <br />
                  <h2 className="font-display md:pt-4 text-7xl font-light inline hover:underline">
                    <Link to="/assinatura">Seja um assinante</Link>
                  </h2>
                </div>
              </div>
            </div>
          </section>

          <section className="pl-8">
            <div className=" pr-4 md:pr-0 md:pl-0 flex grow flex-auto w-4/5 py-16 pl-12">
              <div>
                <h2 className="font-display text-6xl xl:text-7xl font-light hover:underline">
                  <a
                    href="https://sacolavirtual.com/sacola/999"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ficou pra
                    <br />
                    última hora?
                  </a>
                </h2>
                <br />
                <p className="inline">
                  Confira uma seleção de produtos disponíveis para entrega em
                  até 24h.
                </p>
              </div>
              <div className="">
                <img className="place-self-end" src={Garrafas} alt="" />
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="block  md:hidden">
        <div className=" h-full flex flex-col ">
          <section className="bg-greyone pb-6 px-8 flex w-full ">
            <div className="pt-12  ">
              <div>
                <div className="mb-4">
                  <h1 className="text-7xl font-display font-light decoration-1 hover:underline leading-14">
                    <a
                      href="https://sacolavirtual.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Receba a Junta em casa
                    </a>
                  </h1>
                </div>

                <div className="py-5">
                  <p className="text-base inline">
                    Veja a disponibilidade completa de produtos. A feira da
                    nossa comunidade, online.
                  </p>
                  <br />
                  <br />
                  {cycles.map((cycle) => {
                    var deliveryDate = new Date(cycle.deliveryDate);
                    deliveryDate.setDate(deliveryDate.getDate() + 1);
                    return (
                      <a
                        key={cycle.deliveryDate}
                        href="https://sacolavirtual.com/sacola/999"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <YellowButton>
                          Entrega{" "}
                          {new Intl.DateTimeFormat("pt-BR", {
                            weekday: "long",
                            month: "2-digit",
                            day: "2-digit",
                          }).format(deliveryDate)}
                        </YellowButton>
                      </a>
                    );
                  })}
                </div>
                <div>
                  <img src={Caixa} alt="" />
                </div>
              </div>
            </div>
          </section>
          <section className=" w-full flex h-full justify-center">
            <div className="  w-11/12  px-2 my-8 flex">
              <div className=" w-100">
                <h2 className="font-display text-5xl xl:text-6xl font-light hover:underline  ">
                  <a
                    href="https://benfeitoria.com/projeto/ajuntadosdecarteirinha"
                    
                    target="_blank"
                    rel="noreferrer"
                  >
                    Quer ser um ajuntado de carteirinha?
                  </a>
                </h2>
              </div>
              <div className="mt-1 h-full ">
                <img className="place-self-end" src={Card} alt="" />
              </div>
            </div>
          </section>
        </div>

        <div className=" bg-greythree ">
          <section className="bg-greytwo pb-6 flex flex-col items-center">
            <div className=" px-6">
              <div className="text-right">
                <div>
                  <img src={CaixaSuperior} alt="" />
                  <p className="text-base inline">
                    Entregas semanais, quinzenais e mensais
                  </p>
                  <br />
                  <p className="text-base inline ">
                    para você não se preocupar
                  </p>
                  <br />
                  <h2 className="font-display md:pt-4 text-7xl font-light inline hover:underline">
                    <Link to="/assinatura">Seja um assinante</Link>
                  </h2>
                </div>
              </div>
            </div>
          </section>

          <section className="flex justify-center items-center">
            <div className=" flex grow flex-auto w-4/5 py-8 px-6 ">
              <div>
                <h2 className="font-display text-6xl xl:text-7xl font-light hover:underline">
                  <a
                    href="https://sacolavirtual.com/sacola/999"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ficou pra
                    <br />
                    última hora?
                  </a>
                </h2>
                <br />
                <p className="inline">
                  Confira uma seleção de produtos disponíveis para entrega em
                  até 24h.
                </p>
              </div>
              <div>
                <img className="place-self-end" src={Garrafas} alt="" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default Layout;


