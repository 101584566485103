const GrayButton = (props) => {
  return (
    <button
      className={`rounded xl:rounded-2xl bg-grey transition duration-300 hover:bg-lightGrey px-12 py-4 text-xl font-semibold shadow-lg ${
        props.wfull ? "w-full" : ""
      } ${props.disabled ? "opacity-50 shadow-none cursor-not-allowed" : ""}`}
      {...props}
    >
      {props.children}
    </button>
  );
};

export default GrayButton;
