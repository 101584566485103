export const deliveryData = {
  "Rio de Janeiro":{
    "Abolição" : "R$15",
    "Água Santa": "R$15",
    "Alto da Boa Vista": "R$15",
    "Andaraí": "R$15",
    "Anil": "R$15",
    "Barra da Tijuca": "R$15",
    "Benfica": "R$15",
    "Bonsucesso": "R$15",
    "Botafogo": "R$15",
    "Cachambi": "R$15",
    "Cacuia": "R$15",
    "Catete": "R$15",
    "Catumbi": "R$15",
    "Centro": "R$15",
    "Cidade Nova": "R$15",
    "Cidade Universitária": "R$15",
    "Cocotá": "R$15",
    "Copacabana": "R$15",
    "Cosme Velho": "R$15",
    "Del Castilho": "R$15",
    "Curicica": "R$15",
    "Quintino Bocaiúva": "R$15",
    "Encantado": "R$15",
    "Engenho de Dentro": "R$15",
    "Engenho Novo": "R$15",
    "Estácio": "R$15",
    "Flamengo": "R$15",
    "Freguesia": "R$15",
    "Freguesia (Jacarepaguá)": "R$15",
    "Galeão": "R$15",
    "Gamboa": "R$15",
    "Gávea": "R$15",
    "Glória": "R$15",
    "Grajaú": "R$15",
    "Higienópolis": "R$15",
    "Humaitá": "R$15",
    "Ilha do governador - Bancários": "R$15",
    "Bancários": "R$15",
    "Ipanema": "R$15",
    "Jacarepaguá": "R$15",
    "Jardim Botânico": "R$15",
    "Jardim Carioca": "R$15",
    "Jardim Guanabara": "R$15",
    "Jardim América": "R$20",
    "Lapa": "R$15",
    "Lagoa": "R$15",
    "Laranjeiras": "R$15",
    "Leblon": "R$15",
    "Leme": "R$15",
    "Lins de Vasconcelos": "R$15",
    "Maracanã": "R$15",
    "Maria da Graça": "R$15",
    "Méier": "R$15",
    "Moneró": "R$15",
    "Olaria": "R$15",
    "Pechincha": "R$15",
    "Piedade": "R$15",
    "Pilares": "R$15",
    "Pitangueiras": "R$15",
    "Portuguesa": "R$15",
    "Praça da Bandeira": "R$15",
    "Praia da Bandeira": "R$15",
    "Ramos": "R$15",
    "Recreio dos Bandeirantes": "R$15",
    "Riachuelo": "R$15",
    "Ribeira": "R$15",
    "Rio Comprido": "R$15",
    "Rocha": "R$15",
    "Sampaio": "R$15",
    "Santa Teresa": "R$15",
    "Santo Cristo": "R$15",
    "São Conrado": "R$15",
    "São Cristóvão": "R$15",
    "São Francisco Xavier": "R$15",
    "Saúde": "R$15",
    "Tanque": "R$15",
    "Taquara": "R$15",
    "Tauá": "R$15",
    "Tijuca": "R$15",
    "Todos os Santos": "R$15",
    "Vasco da Gama": "R$15",
    "Vila Isabel": "R$15",
    "Urca": "R$15",
    "Zumbi": "R$15"
  },

  "Niterói": {
    "Fátima": "R$20",
    "Boa Viagem": "R$20",
    "Cachoeiras": "R$20",
    "Charitas": "R$20",
    "Gragoatá": "R$20",
    "Icaraí": "R$20",
    "Ingá": "R$20",
    "Centro": "R$20",
    "Jurujuba": "R$20",
    "São Domingos": "R$20",
    "Pe Pequeno": "R$20",
    "Ponta D'Areia": "R$20",
    "Santa Rosa": "R$20",
    "São Francisco": "R$20",
    "Vital Brazil": "R$20",
    "Fonseca": "R$20",
    "Santa Bárbara": "R$20",
    "Santana": "R$20",
    "São Lourenço": "R$20",
    "Tenente Jardim": "R$20",
    "Cubango": "R$20",
    "Atalaia": "R$20",
    "Camboinhas": "R$30",
    "Itacoatiara": "R$30",
    "Itaipu": "R$30",
    "Jardim Imbuí": "R$30",
    "Piratininga": "R$30",
    "Santo Antônio": "R$30",
    "Badu": "R$30",
    "Sapê": "R$30",
    "Vila Progresso": "R$30",
    "Maria Paula": "R$30",
    "Serra Grande": "R$30",
    "Maravista": "R$30",
  
}
};

export default deliveryData;
