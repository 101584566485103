const YourSubscription = (props) => {
  return (
    <div className=" px-4 py-3 sm:py-8 sm:px-6  border border-whiteSmoke rounded shadow-lg">
      <h3 className="border-yellow border-b-4 inline">Sua assinatura</h3>
      <p className="my-4">
        Aqui você vê quais produtos você irá receber assinando o plano
        escolhido.
      </p>
      <div className="grid sm:grid-cols-2">
        <img
          src={props.selectedBox.gallery[0].name}
          className="object-cover rounded max-h-100"
          alt={props.selectedBox.title}
        />

        <div className="mt-4 sm:p-6">
          <span className="font-extrabold text-2xl">
            {props.selectedBox.title}
          </span>
          <div className="grid grid-cols-2 my-4">
            {props.selectedBox.productsBySize[props.sizeIndex].items.map(
              (product, i) => (
                <p key={i}>{product}</p>
              )
            )}
          </div>
        </div>
      </div>
      <p className="sm:mt-5 px-1 text-sm sm:text-lg">
        {props.selectedBox.description}
      </p>
    </div>
  );
};

export default YourSubscription;
