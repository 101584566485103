export const bread = [
  {
    id: "cp1",
    category: "Pão",
    products: [
      {
        id: "p1",
        type: "Pão P",
        options: ["Sourdough (350g)"],
      },
      {
        id: "p2",
        type: "Pão M",
        options: [
          "Sourdough (500g)",
          "Pain de Campagne (500g)",
          "Focaccia (500g)",
        ],
      },
      {
        id: "p3",
        type: "Pão G",
        options: [
          "Sourdough (1kg)",
          "Sourdough Crosta de Grãos (800g)",
          "Sourdough Integral (700g)",
          "Focaccia (800g)",
        ],
      },
    ],
  },
];
