import InfoModel from "./InfoModel";
import Section from "../../Layout/Section/Section";

const SubscriptionsForYou = (props) => {
  return (
    <Section pt>
      <div
        className={`flex flex-col md:flex-row ${
          props.right ? "md:flex-row-reverse pt-8 md:pt-0" : ""
        }`}
      >
        <img
          src={props.boxImage}
          alt="Caixa da Junta"
          className="w-3/4 md:w-1/2 object-contain self-center"
        />
        <div className="flex items-center">
          <InfoModel subscription={props.subscription} />
        </div>
      </div>
    </Section>
  );
};

export default SubscriptionsForYou;
