import Batata from "../../assets/images/svg/Batata";
import Caixa from "../../assets/images/svg/Caixa";
import Caminhao from "../../assets/images/svg/Caminhao";
import Container from "../Layout/Container/Container";

const daysInfo = [
  {
    id: "d1",
    title: "para receber na quarta-feira",
    subtitle: "finalize seu pedido até segunda-feira, às 14h",
  },
  {
    id: "d2",
    title: "para receber no sábado",
    subtitle: "finalize seu pedido até quinta-feira, às 14h",
  },
];
const DeliveryInformation = () => {
  return (
    <>
      <div className="relative h-80vh overflow-hidden">
        <Batata />
        <Container className=" flex flex-col sm:flex-row items-center justify-around sm:justify-between h-full">
          <div className="mb-20 sm:mb-0 text-center sm:pb-20 sm:text-left">
            <div className="pb-4 flex justify-center sm:justify-start ">
              <Caminhao />
            </div>
            <span className="text-xl sm:text-2xl lg:text-h3 font-bold leading-normal">
              Entregas quartas e sábados
            </span>
            {daysInfo.map((day) => (
              <p key={day.id} className="text-base md:text-xl my-5 sm:my-7">
                <span className="font-bold">{day.title}</span>
                <br />
                {day.subtitle}
              </p>
            ))}
            <p className="max-w-md xl:max-w-2xl">
              *Se o pedido for finalizado na véspera do dia de entrega,
              <br className="hidden sm:inline" /> você receberá sua primeira
              caixa na próxima semana.
              <br/><br/>
              * Para caixas mensais as entregas são na primeira
              <br/>
               ou terceira semana do mês.
              <br/><br/>
              Suspenda ou reagende sempre que precisar.
              <br/>
              Alterações devem ser solicitadas 
              <br/>
              até 48h antes da data de entrega mais próxima.

            </p>
          </div>

          <div className="hidden sm:block">
            <Caixa />
          </div>
        </Container>
      </div>
      <div className="flex justify-center sm:hidden">
        <Caixa />
      </div>
    </>
  );
};
export default DeliveryInformation;
