import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { groupButtonData } from "../../data/group-button-data";
import { customerActions } from "../../store/customers";
import SubscriptionButton from "../Buttons/SubscriptionButton";
import LinkToVaButton from "../Buttons/LinkToVaButton";
import GroupButton from "../GroupButton/GroupButton";
import InputCep from "../InputCep/InputCep";
import BoxPrice from "./BoxPrice";

const sizeButtons = groupButtonData[0];
const frequencyButtons = groupButtonData[1];

const Options = (props) => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);

  const variablePrice =
    props.box.productsBySize[props.size].prices[props.frequency].price;
  useEffect(() => {
    dispatch(
      customerActions.updateCustomer({
        boxId: props.box.productsBySize[props.size].prices[props.frequency].id,
      })
    );
  }, [dispatch]);

  const [price, setPrice] = useState(variablePrice);
  const [cep, setCep] = useState("");

  const changeSizeHandler = (event) => {
    const currentSize = event.target.value;
    setPrice(
      props.box.productsBySize[currentSize].prices[props.frequency].price
    );
    props.stateSizeHandler(currentSize);

    dispatch(
      customerActions.updateCustomer({
        boxId: props.box.productsBySize[currentSize].prices[props.frequency].id,
      })
    );
  };

  const changeFrequencyHandler = (event) => {
    if (!props.box.buttons) {
      return;
    }
    const currentFrequency = event.target.value;
    setPrice(
      props.box.productsBySize[props.size].prices[currentFrequency].price
    );
    props.stateFrequencyHandler(currentFrequency);

    dispatch(
      customerActions.updateCustomer({
        boxId: props.box.productsBySize[props.size].prices[currentFrequency].id,
      })
    );
  };

  const getCepValue = (value) => {
    setCep(value.target.value.replace(/[^0-9]/g, ""));
    dispatch(
      customerActions.updateCustomer({
        deliveryAddress: { cep: value.target.value },
      })
    );
  };

  return (
    <div className="mt-4 lg:mt-0 py-12 w-full text-center sm:border border-grey rounded-xl">
      <h3 className="border-grey">{props.box.title}</h3>
      <div role="math" className="mb-8">
        <BoxPrice
          price={price}
          period={frequencyButtons.buttons[props.frequency].period}
        />
      </div>
      <div
        className={`sm:mx-24 xl:mx-32 2xl:mx-44 border-t border-b py-8 px-6 border-grey flex justify-center space-x-20`}
      >
        <GroupButton
          button={"w-full"}
          data={frequencyButtons}
          onClick={changeFrequencyHandler}
          groupHeader={"flex justify-center mb-4"}
          internalDiv={"flex w-full flex-col h-full space-y-3"}
          defaultButton={props.frequency}
          disabledButton={props.box.buttons}
        />
        {props.box.buttons && (
          <GroupButton
            button={"w-full"}
            data={sizeButtons}
            onClick={changeSizeHandler}
            groupHeader={"flex justify-center mb-4"}
            internalDiv={"flex w-full flex-col h-full space-y-3"}
            defaultButton={props.frequency}
            disabledButton={props.box.buttons}
          />
        )}
      </div>

      <div className="grid grid-cols-1 xl:mt-0 py-4 sm:pt-8 sm:pb-0">
        <div className="w-full flex flex-col justify-center items-center">
          <h5 className="mb-4">Verifique a disponibilidade da entrega</h5>
          <InputCep
            delivery={true}
            cep={cep}
            onChange={getCepValue}
            pClassName="md:w-72 md:mx-auto h-6 flex items-center justify-center my-4"
          />
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <SubscriptionButton
          href={`/dados-entrega/${customer.boxId}${
            cep.length === 8 ? "?cep=" + cep : ""
          }`}
          wfull="true"
        />
        <LinkToVaButton
          href={`https://aceitamosva.juntalocal.com/assinatura`}
          wfull="true"
        />
      </div>
    </div>
  );
};

export default Options;
