import { HashLink } from "react-router-hash-link";

const HeaderMobile = (props) => {
  return (
    <div className="lg:hidden fixed top-mobile-header right-0 left-0 z-50 bg-white mobile-menu">
      <ul>
        {props.linksNavbar.map((linkNavbar, index) => (
          <li
            key={linkNavbar.key}
            className={`p-7 border-b ${
              index === 0 ? "border-t mt-2" : ""
            } border-grey`}
          >
            {linkNavbar.href ? (
              <a
                href={linkNavbar.href}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
                onClick={props.handleShowMenu}
              >
                {linkNavbar.key}{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#D6D6D6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  />
                </svg>
              </a>
            ) : (
              <HashLink
                smooth
                to={linkNavbar.to}
                onClick={props.handleShowMenu}
              >
                {linkNavbar.key}
              </HashLink>
            )}
          </li>
        ))}
        {props.linksMenu.map((linkMenu) => (
          <li key={linkMenu.title} className="p-7 border-b border-grey">
            <a
              href={linkMenu.href}
              target={linkMenu.target}
              rel="noopener noreferrer"
              className="flex items-center"
              onClick={props.handleShowMenu}
            >
              {linkMenu.title}{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#D6D6D6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                />
              </svg>
            </a>
          </li>
        ))}
      </ul>
      <div
        className="fixed bg-black bg-opacity-50 h-screen w-full"
        onClick={props.handleShowMenu}
      ></div>
    </div>
  );
};

export default HeaderMobile;
