import Close from "../../assets/images/svg/Close";

const CategoryModal = (props) => {
  const products = props.modalData.products;

  products && products.sort((a, b) => a.type.localeCompare(b.type));

  return (
    <>
      {props.isModalOpen && (
        <>
          <div
            onClick={props.closeModal}
            className="z-30 w-full h-full fixed bg-black bg-opacity-50 flex justify-center"
          ></div>
          <div className="bg-white fixed z-40 top-1/2 sm:left-1/2 transform sm:-translate-x-1/2 -translate-y-1/2 rounded-xl max-w-lg lg:mt-8">
            <div className="flex justify-between items-center p-6 border-b border-grey">
              <h4>
                {props.modalData.category} que poderão
                <br /> compor a sua caixa
              </h4>
              <button onClick={props.closeModal}>
                <Close />
              </button>
            </div>
            <div className="p-6 grid gap-7 max-h-1/2 overflow-y-auto">
              {products.map((product) => (
                <div key={product.id}>
                  <h5 className="border-b-2 border-yellow inline mb-4">
                    {product.type}
                  </h5>
                  <ul className="px-4 pt-3">
                    {product.options
                      .sort((a, b) => a.localeCompare(b))
                      .map((option) => (
                        <li key={option} className="text-base mt-2">
                          {option}
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
            <p className="border-t border-grey p-6">
              Se você não puder consumir algum dos produtos acima, informe no
              campo de <span className="font-bold">Observações Adicionais</span>{" "}
              na próxima página.
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default CategoryModal;
