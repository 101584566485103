const YellowButton = (props) => {
  return (
    <button
      className={`rounded xl:rounded-2xl bg-yellow transition duration-300 hover:bg-lightYellow px-9 py-4 text-xl font-semibold shadow-lg ${
        props.wfull ? "w-full" : ""
      } ${props.disabled ? "opacity-50 shadow-none cursor-not-allowed" : ""}`}
      {...props}
    >
      {props.children}
    </button>
  );
};

export default YellowButton;
