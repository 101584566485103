import GrayButton from "../Layout/GrayButton/GrayButton";

const LinkToVaButton = (props) => {
  return (
    <>
      <a href={props.href}className="hidden lg:block ">
        <GrayButton>Tem vale alimentação? Assine aqui!</GrayButton>
      </a>
      <a href={props.href} className="block lg:hidden">
        <GrayButton>Tem VA? Assine aqui!</GrayButton>
      </a>
    </>
  );
};

export default LinkToVaButton;
