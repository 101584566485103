import { monthlyData } from "../../../data/monthly-data";
import ProductCard from "../../ProductCard/ProductCard";
import Section from "../../Layout/Section/Section";
import ProductSwiper from "../../ProductSwiper/ProductSwiper";

const Monthly = () => {
  return (
    <>
      <div className="xl:hidden border-b border-grey">
        <div className="text-center sm:text-left">
          <h4 className="text-center font-extrabold mb-4">
            Descobertas deliciosas para você!
          </h4>
        </div>
        <div className="hidden xl:grid sm:grid-cols-2 xl:grid-cols-3 gap-3 xl:gap-7 sm:pt-4">
          {monthlyData.map((box) => (
            <ProductCard box={box} key={box.title} />
          ))}
        </div>
        <ProductSwiper data={monthlyData} />
      </div>
      <div className="hidden xl:block">
        <Section id="descobertas-mensais" pt={true} border={true}>
          <div className="text-center sm:text-left">
            <h4 className="text-center font-extrabold mb-4">
              Descobertas deliciosas para você!
            </h4>
          </div>
          <div className="hidden xl:grid sm:grid-cols-2 xl:grid-cols-3 gap-3 xl:gap-7 sm:pt-4">
            {monthlyData.map((box) => (
              <ProductCard box={box} key={box.title} />
            ))}
          </div>
          <ProductSwiper data={monthlyData} />
        </Section>
      </div>
    </>
  );
};

export default Monthly;
