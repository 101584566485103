import { createSlice } from '@reduxjs/toolkit';

const initialCustomerState = {
  boxId: '',
  boxName: "",
  frequency: "",
  size: "",
  value: "",
  firstName: "",
  lastName: "",
  email: "",
  birth: "",
  tel: "",
  deliveryAddress: {
    cep: '',
    city: "",
    nbhd: "",
    street: "",
    number: "",
    complement: "",
  },
  billingAddress: {
    cep: '',
    city: "",
    nbhd: "",
    street: "",
    number: "",
    complement: "",
  },
  day: "",
  information: "",
  registerDate: "",
  receivingOption: 'Eu mesmo ou alguém na minha residência',
  returnable: false,
  sameAddress: true,
  acceptTerms: false,
  cardNumber: '',
  cardName: '',
  cardExpirationDate: '',
  cvv: '',
  cpf: ''
};

const customerSlice = createSlice({
  name: 'customer',
  initialState: initialCustomerState,
  reducers: {
    updateCustomer(state, action) {
      state = {
        ...state,
        ...action.payload
      }
      return state
    }
  }
});

export const customerActions = customerSlice.actions;
export default customerSlice.reducer;
