import YTIcon from "../../assets/images/youtube-icon.png";
import FacebookIcon from "../../assets/images/facebook-icon.png";
import InstagramIcon from "../../assets/images/instagram-icon.png";
import Container from "../Layout/Container/Container";

const linkImg = [
  {
    alt: "Facebook",
    href: "https://www.facebook.com/ajuntalocal/",
    src: FacebookIcon,
  },
  {
    alt: "Instagram",
    href: "https://www.instagram.com/ajuntalocal/",
    src: InstagramIcon,
  },
  { alt: "YouTube", href: "https://www.youtube.com/Juntalocal", src: YTIcon },
];

const linkAboutUs = [
  { name: "Feiras", href: "https://sobre.juntalocal.com/feira" },
  { name: "Sobre nós", href: "https://sobre.juntalocal.com/sobre" },
  { name: "Quem Faz", href: "https://sobre.juntalocal.com/produtores" },
  { name: "Revista da Junta", href: "https://revista.juntalocal.com/" },
  { name: "Quero ser produtor", href: "https://podio.com/webforms/17437547/1172669" },
  {
    name: "Fique por dentro",
    href: "https://share.hsforms.com/1eRfJc-dtTxCkZpJ109CoKw5m3sr",
  },
];

const linkFaq = [
  { name: "Dúvidas", href: "https://sobre.juntalocal.com/duvidas" },
  { name: "Informações da Entrega", href: "bairros-e-entrega" },
  { name: "Datas das Sacolas", href: "https://sacolavirtual.com/" },
  {
    name: "Política de Privacidade",
    href: "https://sobre.juntalocal.com/privacidade",
  },
  { name: "Termos de Uso", href: "https://sobre.juntalocal.com/termos" },
];

const Footer = () => {
  return (
    <footer className="bg-yellow py-8">
      <Container>
        <div className="grid md:grid-cols-3 border-b pb-8">
          <p className="self-end text-justify">
            A Junta Local é uma comunidade que busca transformar o sistema alimentar pela comida boa, local e justa. Acreditamos que comer é um ato transformador e que podemos melhorar as nossas vidas do ponto de vista individual e coletivo através de plataformas inovadoras que redefinem as relações entre produtores locais e consumidores.
          </p>

          <div className="md:col-start-3">
            <div className="grid grid-cols-3 my-5 md:max-w-xs md:ml-auto">
              {linkImg.map((img) => (
                <div className="mx-auto md:mr-0 " key={img.href}>
                  <a href={img.href} target="_blank" rel="noreferrer">
                    <img src={img.src} alt={img.alt} className="inline" />
                  </a>
                </div>
              ))}
            </div>
            <div className="text-center md:text-right">
              <h4 >
                  <a href="https://share.hsforms.com/1eRfJc-dtTxCkZpJ109CoKw5m3sr"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline flex items-center justify-center md:justify-end"
                  >
                Inscreva-se e fique por dentro!
                <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 ml-1"
                      fill="none"
                      viewBox="0 0 22 22"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                      />
                    </svg>
              </a></h4>
              <br/> Novidades, descontos, histórias de produtores locais
              <br/>e outras informações da nossa comunidade.
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-5 mt-8">
          <div>
            <h4 className="mb-3.5">Conheça a Junta</h4>
            <ul className="font-extrabold">
              {linkAboutUs.map((link) => (
                <li key={link.name}>
                  <a
                    // href={link.href}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline text-sm sm:text-base lg:text-lg flex items-center"
                  >
                    {link.name}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3 w-3 ml-1"
                      fill="none"
                      viewBox="0 0 22 22"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                      />
                    </svg>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h4 className="mb-3.5">Precisa de ajuda?</h4>
            <ul className="font-extrabold">
              {linkFaq.map((link) => (
                <li key={link.name}>
                  <a
                    // href={link.href}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline text-sm sm:text-base lg:text-lg flex items-center"
                  >
                    {link.name}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3 w-3 ml-1"
                      fill="none"
                      viewBox="0 0 22 22"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                      />
                    </svg>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="grid grid-cols-2 justify-between mt-12">
          <p>&copy; Junta Local, todos os direitos reservados.</p>
          <p className="text-right">12.255.365/1004-95</p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
