import Auto from "./Auto";
import Manual from "./Manual";

const Carousel = (props) => {
  if (props.type === "auto") {
    return (
      <Auto className={props.className} pagination gallery={props.gallery} />
    );
  } else if (props.type === "manual") {
    return <Manual className={props.className} gallery={props.gallery} />;
  }
};

export default Carousel;
