import { boxSubscriptionData } from "../../../data/box-subscription-data";
import ProductCard from "../../ProductCard/ProductCard";
import Section from "../../Layout/Section/Section";
import ProductSwiper from "../../ProductSwiper/ProductSwiper";

const BoxSubscription = () => {
  return (
    <div id="caixas-da-junta">
      <div className="xl:hidden border-b border-grey">
        <div className="text-center sm:text-left">
          <h4 className="text-center font-extrabold mb-4 px-4 md:px-0">
            Caixas para levar um pedaço da feira para a sua casa, direto da
            nossa comunidade local!
          </h4>
        </div>
        <div className="hidden xl:grid sm:grid-cols-2 xl:grid-cols-3 gap-3 xl:gap-7 sm:pt-4">
          {boxSubscriptionData.map((box) => (
            !box.vegan &&<ProductCard box={box} key={box.title} />
          ))}
        </div>
        <ProductSwiper data={boxSubscriptionData} />
      </div>
      <div className="hidden xl:block">
        <Section pt={false} border={true}>
          <div className="text-center sm:text-left">
            <h4 className="text-center mt-12 font-extrabold">
              Caixas para levar um pedaço da feira para a sua casa, direto da
              nossa comunidade local!
            </h4>
          </div>
          <div className="hidden xl:grid sm:grid-cols-2 xl:grid-cols-3 gap-3 xl:gap-7 sm:pt-4">

            {boxSubscriptionData.map((box) => (
              !box.vegan &&<ProductCard box={box} key={box.title} />
            ))}
          </div>
          <ProductSwiper data={boxSubscriptionData} />
        </Section>
      </div>
    </div>
  );
};

export default BoxSubscription;
