export const grain = [
  {
    id: "cg2",
    category: "Farinhas",
    products: [
      {
        id: "ff1",
        type: "Farinha de Trigo",
        options: [
          "Farinha de Trigo Branca (500g)",
          "Farinha de Trigo Integral (500g)",
          "Farinha de Trigo Sarraceno (500g)",
        ],
      },
      {
        id: "ff2",
        type: "Farinha de Mandioca",
        options: [
          "Farinha de Mandioca Branca (500g)",
          "Farinha de Mandioca Torrada (500g)",
        ],
      },
      {
        id: "ff3",
        type: "Farinha de Arroz",
        options: ["Farinha de Arroz (500g)"],
      },
      {
        id: "ff4",
        type: "Farinha de Centeio",
        options: ["Farinha de Centeio (500g)"],
      },
      {
        id: "ff5",
        type: "Flocão",
        options: ["Flocão (500g)"],
      },
      {
        id: "ff6",
        type: "Fubá",
        options: ["Fubá (500g)"],
      },
    ],
  },
];
