import { useEffect, useState } from "react";

const ProductImage = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const galleryLength = props.gallery.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % galleryLength);
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  return (
    <div className={`overflow-hidden ${props.className}`}>
      <div
        className={`whitespace-nowrap rounded-xl ${ props.background ? "border border-grey bg-whiteSmoke " : "" }`}
      >
        {props.gallery.map((image, i) => (
          <img
            key={image.name}
            src={image.name}
            alt=""
            className={
                  i === currentIndex
                    ? "inline-flex object-cover rounded-xl items-center justify-center"
                    : "hidden"
                }
          />
        ))}
      </div>
      {props.pagination && (
        <div className="flex justify-center">
          {props.gallery.map((image, i) => (
            <button
              key={image.name}
              onClick={() => setCurrentIndex(i)}
              className={`rounded-full h-3 w-3 ${
                i === currentIndex ? "bg-yellow" : "bg-grey bg-opacity-70"
              } m-2`}
            ></button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductImage;
