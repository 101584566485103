import Batata from "../../../assets/images/carousel/Batata.jpg";
import Campo from "../../../assets/images/carousel/Campo.jpg";
import Feira from "../../../assets/images/carousel/Feira.jpg";
import Moinho from "../../../assets/images/carousel/Moinho.jpg";
import Pao from "../../../assets/images/carousel/Pao.jpg";
import Salada from "../../../assets/images/carousel/Salada.jpg";
import Tomates from "../../../assets/images/carousel/Tomates.jpg";

export const CAROUSEL = [
  { type: "object-cover", name: Feira },
  { type: "object-cover", name: Campo },
  { type: "object-cover", name: Batata },
  { type: "object-cover", name: Tomates },
  { type: "object-cover", name: Moinho },
  { type: "object-cover", name: Pao },
  { type: "object-cover", name: Salada },
];

