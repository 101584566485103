import React from "react";
import whatsapp from "../../assets/images/whatsapp.png";

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?phone=5521984622799&text=Ol%C3%A1,%20quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Assina%20Junta`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <button
      onClick={handleWhatsAppClick}
      className="fixed bottom-10 right-10 p-2 rounded-full bg-green-500 text-white z-10"
    >
      <img src={whatsapp} alt="WhatsApp da Junta" className="w-80 " />
    </button>
  );
};

export default WhatsAppButton;
