import { createSlice } from '@reduxjs/toolkit';

const initialCycleState = {
  currentCycles: [{
    startAt: "",
    endAt: "",
    deliveryDate: "2022-04-27"
  }]
};

const cycleSlice = createSlice({
  name: 'cycle',
  initialState: initialCycleState,
  reducers: {
    setCurrentCycle(state, action) {
      state = action.payload;
      return state;
    }
  }
});

export const getCurrentCycle = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      var query = `{
        currentCycles {
          startAt
          endAt
          deliveryDate
        }
      }`;

      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL,
        {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query: query })
        }
      );

      const data = await response.json();

      return data.data;
    };

    try {
      const cycleResponse = await fetchData();
      dispatch(cycleActions.setCurrentCycle(cycleResponse));
    } catch (error) {
      dispatch(cycleActions.setCurrentCycle(initialCycleState));
      throw new Error('Could not fetch data: ' + error.message);
    }
  };
};

export const cycleActions = cycleSlice.actions;
export default cycleSlice.reducer;

