/* eslint-disable react/jsx-no-target-blank */
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { customerActions } from "../store/customers";
import InputMask from "react-input-mask";
import { useParams, useHistory, Link } from "react-router-dom";
import GroupButton from "../components/GroupButton/GroupButton";
import InputCep from "../components/InputCep/InputCep";
import { groupButtonData } from "../data/group-button-data";
import PurchaseSummary from "../components/PaymentContent/PurchaseSummary/PurchaseSummary";
import YourSubscription from "../components/PaymentContent/YourSubscription/YourSubscription";
import YellowButton from "../components/Layout/YellowButton/YellowButton";
import Container from "../components/Layout/Container/Container";
import { formattedCurrentDate } from "../helpers/DateUtils";
import { selectedBoxHelper } from "../helpers/SelectedBoxHelper";
import { sendGAEvent } from "../helpers/SendGAEvent";

const sizeButtons = groupButtonData[0];
const frequencyButtons = groupButtonData[1];
const daysButtons = groupButtonData[2];

const DeliveryForm = () => {
  const { boxId } = useParams();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);

  let boxHelper = selectedBoxHelper(boxId);
  let selectedBox = boxHelper.selectedBoxVar;
  let indexArr = boxHelper.indexArr;

  const [frequencyIndex, setFrequencyIndex] = useState(indexArr[1]);
  const [sizeIndex, setSizeIndex] = useState(indexArr[0]);
  const [cepResponse, setCepResponse] = useState({});
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    dispatch(
      customerActions.updateCustomer({
        frequency: frequencyButtons.buttons[indexArr[1]].title,
        size: sizeButtons.buttons[indexArr[0]].title,
        boxName: selectedBox.title,
        boxId: customer.boxId || boxId,
        value:
          selectedBox.productsBySize[indexArr[0]].prices[indexArr[1]].price,
      })
    );
  }, []);

  useEffect(() => {
    const validateForm = () => {
      setValidation(
        customer.firstName.length > 0 &&
          customer.lastName.length > 0 &&
          customer.birth.replace(/[^0-9]/g, "").length === 8 &&
          customer.tel.replace(/[^0-9]/g, "").length === 11 &&
          customer.email.length > 5 &&
          customer.email.includes("@") &&
          customer.deliveryAddress?.city?.length > 0 &&
          customer.deliveryAddress?.number?.length > 0 &&
          customer.day != false
      );
    };

    validateForm();
  }, [customer]);

  const purchaseSummary = [
    { title: "Plano", option: selectedBox.title },
    {
      title: "Tamanho",
      option: `${customer.size}`,
    },
    {
      title: "Local para entrega",
      option: customer.deliveryAddress?.city
        ? `${customer.deliveryAddress?.street}, ${
            customer.deliveryAddress?.number &&
            customer.deliveryAddress?.number + ","
          } ${customer.deliveryAddress?.city}, ${customer.deliveryAddress?.cep}`
        : "",
    },
    { title: "Dia para receber", option: customer.day },
  ];

  const cepResponseHandler = (value) => {
    setCepResponse(value);
    dispatch(
      customerActions.updateCustomer({
        deliveryAddress: {
          ...customer.deliveryAddress,
          cep: value.cep,
          nbhd: value.bairro,
          street: value.logradouro,
          city: value.localidade,
        },
      })
    );
  };

  const getDayValue = (e) => {
    e.preventDefault();
    dispatch(
      customerActions.updateCustomer({
        day: daysButtons.buttons[e.target.value].title,
      })
    );
  };
  const getFrequencyValue = (e) => {
    e.preventDefault();
    setFrequencyIndex(e.target.value);
    dispatch(
      customerActions.updateCustomer({
        frequency: frequencyButtons.buttons[e.target.value].title,
        value:
          selectedBox.productsBySize[sizeIndex].prices[e.target.value].price,
        boxId: selectedBox.productsBySize[sizeIndex].prices[e.target.value].id,
      })
    );
  };
  const getSizeValue = (e) => {
    e.preventDefault();
    setSizeIndex(e.target.value);
    dispatch(
      customerActions.updateCustomer({
        size: sizeButtons.buttons[e.target.value].title,
        value:
          selectedBox.productsBySize[e.target.value].prices[frequencyIndex]
            .price,
        boxId:
          selectedBox.productsBySize[e.target.value].prices[frequencyIndex].id,
      })
    );
  };

  const formAddressHandler = (e) => {
    dispatch(
      customerActions.updateCustomer({
        deliveryAddress: {
          ...customer.deliveryAddress,
          [e.target.id]: e.target.value,
        },
      })
    );
  };

  const formDataHandler = (e) => {
    dispatch(
      customerActions.updateCustomer({
        [e.target.id]: e.target.value,
        registerDate: formattedCurrentDate(),
      })
    );
  };

  const history = useHistory();
  const onClickSubscriptionHandler = (e) => {
    e.preventDefault();
    const propEvent = {
      category: "payment_info",
      action: "add_payment_info",
      label: "subscriptionButton",
    };
    sendGAEvent(propEvent, e);
    history.push(`/pagamento/${customer.boxId}`);
  };

  return (
    <Container>
      <div className="grid lg:grid-cols-2 gap-6 my-7 sm:my-11">
        <div className="flex flex-col sm:grid grid-rows-2 gap-6 ">
          <PurchaseSummary
            className="hidden sm:block"
            purchaseSummary={purchaseSummary}
            period={frequencyButtons.buttons[frequencyIndex].period}
            formData={customer}
          />
          <YourSubscription selectedBox={selectedBox} sizeIndex={sizeIndex} />
        </div>
        <div className="bg-whiteSmoke border border-grey rounded px-4 py-3 sm:py-8 sm:px-6">
          <h3>Dados Pessoais</h3>
          <form className="grid gap-7 my-4 sm:my-9">
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
              <input
                autoComplete="off"
                id="firstName"
                className="border border-grey h-14 px-3 rounded-xl col-span-2"
                type="text"
                placeholder="Nome*"
                onChange={formDataHandler}
                value={customer.firstName}
                required
              />
              <input
                autoComplete="off"
                id="lastName"
                className="border border-grey h-14 px-3 rounded-xl col-span-2"
                type="text"
                placeholder="Sobrenome*"
                onChange={formDataHandler}
                value={customer.lastName}
                required
              />
              <InputMask
                autoComplete="off"
                id="birth"
                mask="99/99/9999"
                type="tel"
                placeholder="Data de Nascimento*"
                onChange={formDataHandler}
                value={customer.birth}
                className="border border-grey rounded-xl w-full px-3 h-14 col-span-2 sm:col-span-1"
                required
              />
              <input
                autoComplete="off"
                id="email"
                className="border border-grey h-14 px-3 rounded-xl col-span-2"
                type="email"
                placeholder="E-mail*"
                onChange={formDataHandler}
                value={customer.email}
                required
              />
              <InputMask
                autoComplete="off"
                id="tel"
                className="border border-grey h-14 px-3 rounded-xl"
                type="tel"
                mask="(99) 99999-9999"
                placeholder="Celular*"
                onChange={formDataHandler}
                value={customer.tel}
                required
              />
              <InputCep
                autoComplete="off"
                delivery={true}
                pClassName="col-span-2 flex items-center justify-center px-3 rounded-xl"
                cepResponseHandler={cepResponseHandler}
                onChange={formAddressHandler}
                cep={customer.deliveryAddress?.cep}
              />
            </div>
            <div className="grid sm:grid-cols-2 gap-4">
              <input
                className="bg-disabledGrey opacity-75 border border-grey h-14 px-3 rounded-xl"
                type="text"
                placeholder="Cidade*"
                required
                value={cepResponse.localidade || ""}
                disabled
                aria-label="city-input"
              />
              <input
                className=" bg-disabledGrey opacity-75 border border-grey h-14 px-3 rounded-xl"
                type="text"
                placeholder="Bairro*"
                required
                disabled
                value={cepResponse.bairro || ""}
              />
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-10 gap-4">
              <input
                className="bg-disabledGrey opacity-75 border border-grey col-span-2 sm:col-span-5 h-14 px-3 rounded-xl"
                type="text"
                placeholder="Endereço*"
                required
                disabled
                value={cepResponse.logradouro || ""}
              />
              <input
                autoComplete="off"
                id="number"
                className="border border-grey sm:col-span-2 h-14 px-3 rounded-xl"
                type="text"
                placeholder="Número*"
                value={customer.deliveryAddress?.number}
                onChange={formAddressHandler}
                required
              />
              <input
                autoComplete="off"
                id="complement"
                className="border border-grey sm:col-span-3 h-14 px-3 rounded-xl"
                type="text"
                placeholder="Complemento"
                value={customer.deliveryAddress?.complement}
                onChange={formAddressHandler}
              />
            </div>
            <div className="space-y-3 sm:space-y-0 sm:grid grid-cols-3 gap-4 ">
              <GroupButton
                button={"w-1/2 sm:w-3/4"}
                data={frequencyButtons}
                onClick={getFrequencyValue}
                externalDiv={
                  "sm:px-3 bg-white rounded-xl border-grey border py-4"
                }
                groupHeader={"mb-3 flex justify-center text-base "}
                internalDiv={
                  "flex flex-col justify-center items-center space-y-2 w-full px-2"
                }
                defaultButton={indexArr[1]}
                disabledButton={selectedBox.buttons}
              />
              {selectedBox.buttons && (
                <GroupButton
                  button={"w-1/2 sm:w-3/4"}
                  data={sizeButtons}
                  onClick={getSizeValue}
                  externalDiv={
                    "sm:px-3 bg-white rounded-xl border-grey border py-4"
                  }
                  groupHeader={"mb-3 flex justify-center text-base "}
                  internalDiv={
                    "flex flex-col justify-center items-center space-y-2 w-full px-2"
                  }
                  defaultButton={indexArr[0]}
                  disabledButton={selectedBox.buttons}
                />
              )}
              <GroupButton
                button={"w-1/2 sm:w-3/4"}
                data={daysButtons}
                onClick={getDayValue}
                externalDiv={
                  "sm:px-3 bg-white rounded-xl border-grey border py-4"
                }
                groupHeader={"mb-3 flex justify-center text-base"}
                internalDiv={
                  "flex flex-col justify-center items-center space-y-2 w-full px-2"
                }
                defaultButton={false}
                disabledButton={true}
              />
            </div>
            <textarea
              className="border border-grey p-3 rounded-xl resize-none"
              id="information"
              name="story"
              rows="5"
              cols="33"
              placeholder="Digite aqui as observações"
              onChange={formDataHandler}
              value={customer.information}
            ></textarea>
            <YellowButton
              type="submit"
              disabled={!validation}
              onClick={onClickSubscriptionHandler}
            >
              Ir para o pagamento
            </YellowButton>
          </form>
          <p className="font-semibold mb-4 text-base xl:text-lg px-8 sm:px-0 sm:max-w-md xl:max-w-4xl ">
            Tem vale alimentação (Alelo, Sodexo e VR)? Chegou sua hora de
            conhecer o Assina Junta!{" "}
          </p>
          <p className="mb-4 text-base xl:text-lg px-8 sm:px-0 sm:max-w-md xl:max-w-4xl ">
            Basta escolher a caixa de assinatura que mais combina com você e
            acessar:{" "} 
            <a className="underline underline-offset-1" href="https://aceitamosva.juntalocal.com/assinatura" target={"_blank"}>
              aceitamosva.juntalocal.com/assinatura
            </a>
          </p>
        </div>
        <PurchaseSummary
          className="sm:hidden block"
          purchaseSummary={purchaseSummary}
          period={frequencyButtons.buttons[frequencyIndex].period}
          formData={customer}
        />
      </div>
    </Container>
  );
};

export default DeliveryForm;
