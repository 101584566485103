import { configureStore } from '@reduxjs/toolkit';

import customerReducer from './customers';
import purchaseReducer from './purchases';
import cycleReducer from './cycles';
import fairReducer from './fairs';

const store = configureStore({
  reducer: {
    customer: customerReducer,
    purchase: purchaseReducer,
    cycle: cycleReducer,
    fair: fairReducer
  }
});

export default store;
