export const mushroom = [
  {
    id: "cc2",
    category: "Cogumelos",
    products: [
      {
        id: "cm1",
        type: "Shiitake",
        options: ["Shiitake (200g)"],
      },
      {
        id: "cm2",
        type: "Paris",
        options: ["Paris (200g)"],
      },
      {
        id: "cm3",
        type: "Shimeji",
        options: ["Shimeji Branco (200g)", "Shimeji Rosa (200g)"],
      },
      {
        id: "cm4",
        type: "Portobello",
        options: ["Portobello (200g)"],
      },
    ],
  },
];
