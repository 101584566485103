export const lac = [
  {
    id: "la1",
    category: "Laticínios",
    products: [
      {
        id: "a1",
        type: "Manteiga",
        options: ["Manteiga (250g)"],
      },
      {
        id: "a2",
        type: "Requeijão",
        options: ["Requeijão (350g)"],
      },
      {
        id: "a3",
        type: "Iogurte",
        options: [
          "Iogurte Integral Natural (1kg)",
          "Iogurte Integral Morango/Maracujá (1kg)",
        ],
      },
      {
        id: "a4",
        type: "Queijo",
        options: [
          "Queijo Canastra (250g)",
          "Queijo Minas Frescal (500g)",
          "Ricota (400g)",
          "Queijo Meia Cura (225g)",
          "Queijo Muçarela (250g)",
          "Queijo Prato Lanche (500g)",
        ],
      },
    ],
  },
];
