export const vegetable = [
  {
    id: "cl1",
    category: "Legumes",
    products: [
      {
        id: "l1",
        type: "Tomate",
        options: [
          "Tomate Italiano (500g)",
          "Tomate Salada (500g)",
          "Tomate Grape (300g)",
        ],
      },
      {
        id: "l2",
        type: "Cenoura",
        options: ["Cenoura (500g)"],
      },
      {
        id: "l3",
        type: "Abobrinha",
        options: ["Abobrinha (500g)"],
      },
      {
        id: "l4",
        type: "Batata",
        options: [
          "Batata Baroa (500g)",
          "Batata Inglesa (500g)",
          "Batata Doce Rosa (500g)",
          "Batata Doce Roxa (500g)",
          "Batata Doce Laranja (500g)",
        ],
      },
      {
        id: "l5",
        type: "Pepino",
        options: ["Pepino Comum (500g)", "Pepino Japonês (500g)"],
      },
      {
        id: "l6",
        type: "Berinjela",
        options: ["Berinjela (500g)"],
      },
      {
        id: "l7",
        type: "Cebola",
        options: ["Cebola Amarela (500g)", "Cebola Roxa (500g)"],
      },
      {
        id: "l8",
        type: "Repolho",
        options: [
          "Repolho Verde (1un, aprox. 600g)",
          "Repolho Roxo (1un, aprox. 600g)",
        ],
      },

      {
        id: "l10",
        type: "Beterraba",
        options: [" Beterraba (500g)"],
      },
      {
        id: "l11",
        type: "Quiabo",
        options: ["Quiabo (300g)"],
      },
      {
        id: "l12",
        type: "Chuchu",
        options: ["Chuchu (500g)"],
      },
      {
        id: "l13",
        type: "Vagem",
        options: ["Vagem Macarrão (300g)", "Vagem Francesa (300g)"],
      },
      {
        id: "l14",
        type: "Brócolis",
        options: [
          "Brócolis Ramoso (1un, aprox. 300g)",
          "Brócolis Americano (1un, aprox. 600g)",
        ],
      },
      {
        id: "l15",
        type: "Abóboras",
        options: ["Abóbora Japonesa (1kg)", "Abóbora Baiana (1kg)"],
      },
      {
        id: "l16",
        type: "Aipim",
        options: ["Aipim (500g)"],
      },
      {
        id: "l17",
        type: "Milho Verde",
        options: ["Milho Verde (2un, aprox. 400g)"],
      },
      {
        id: "l18",
        type: "Pimentão",
        options: [
          "Pimentão Verde (300g)",
          "Pimentão Amarelo (300g)",
          "Pimentão Vermelho (300g)",
        ],
      },
      {
        id: "l19",
        type: "Rabanete",
        options: ["Rabanete (1un, aprox.300g)"],
      },
    ],
  },
];
