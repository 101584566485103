import { citrus } from "./ProductTypesData/citricos-data";
import { mushroom } from "./ProductTypesData/cogumelos-data";
import { pantry } from "./ProductTypesData/despensa-data";
import { leaf } from "./ProductTypesData/folhosas-data";
import { fruit } from "./ProductTypesData/frutas-data";
import { grain } from "./ProductTypesData/grao-data";
import { lac } from "./ProductTypesData/laticinios-data";
import { vegetable } from "./ProductTypesData/legumes-data";
import { bread } from "./ProductTypesData/paes-data";
import { spice } from "./ProductTypesData/temperos-data";

const productsByCategory = [
  ...citrus,
  ...leaf,
  ...fruit,
  ...spice,
  ...mushroom,
  ...grain,
  ...pantry,
  ...bread,
  ...lac,
  ...vegetable,
];

export const productTypes = productsByCategory.sort((a, b) =>
  a.category.localeCompare(b.category)
);
