import { Link } from "react-router-dom";
import logo from "../../assets/images/Logo.png";

const Logo = (props) => {
  return (
    <a href="http://www.juntalocal.com">
      <img src={logo} alt="Junta Local" />
    </a>
  );
};

export default Logo;
