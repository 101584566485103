export const citrus = [
  {
    id: "ccc1",
    category: "Cítricos",
    products: [
      {
        id: "f14",
        type: "Limão",
        options: [
          "Limão Galego (500g)",
          "Limão Tahiti (500g)",
          "Limão Siciliano (500g)",
        ],
      },
    ],
  },
];
