import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNextFairs } from "../store/fairs";

const EventsInfos = () => {
  const dispatch = useDispatch();
  const fairs = useSelector((state) => state.fair.nextFairs);

  useEffect(() => {
    dispatch(getNextFairs());
  }, []);

  return (
    <section>
      {fairs.map((fair) => {
        var startAt = new Date(fair.startAt.replace(" ", "T").replace(" ", ""));
        var endAt = new Date(fair.endAt.replace(" ", "T").replace(" ", ""));

        return (
          <div key={fair.startAt} className="flex">
            <div className="mr-6">
              <div className="mb-3">
                <h3>
                {String(startAt.getDate()).padStart(2, "0")}/{String(startAt.getMonth() + 1).padStart(2, "0")}
                </h3>
                <p>
                  {startAt.toLocaleDateString("pt-BR", { weekday: "long" })}
                </p>
              </div>
              {(startAt.getDate()) !== (endAt.getDate()) &&
                <div>
                  <h3>
                    {String(endAt.getDate()).padStart(2, "0")}/{String(endAt.getMonth() + 1).padStart(2, "0")}
                  </h3>
                  <p>
                    {endAt.toLocaleDateString("pt-BR", { weekday: "long" })}
                  </p>
                </div>
              }
            </div>

            <hr className="border-l-2 border-grey border-solid" />
            <div>
              <hr className="border-t-2 border-grey border-solid" />
              <div className="my-7 bg-greyEvent rounded-lg flex items-center py-8 w-80 lg:w-96">
                <div className="px-5">
                  <h4>{startAt.getHours()}h</h4>
                  <h4>{endAt.getHours()}h</h4>
                </div>
                <div className="pl-6">
                  <h4 className="pb-3">{fair.neighborhood}</h4>
                  <p className="pb-2">{fair.name}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default EventsInfos;
