export const pantry = [
  {
    id: "cd1",
    category: "Despensa",
    products: [
      {
        id: "ds1",
        type: "Café",
        options: ["Café Especial Moído (250g)"],
      },
      {
        id: "ds2",
        type: "Açúcar",
        options: ["Açúcar Mascavo (500g)"],
      },
      {
        id: "g1",
        type: "Arroz",
        options: [
          "Arroz Agulhinha Polido (1kg)",
          "Arroz Agulhinha Integral (1kg)",
          "Arroz Cateto Integral (1kg)",
        ],
      },
      {
        id: "g2",
        type: "Feijão",
        options: ["Feijão Preto (500g)", "Feijão Carioquinha (500g)"],
      },
    ],
  },
];
