import { Link } from "react-router-dom";
import ThankYouImg from "../assets/images/obrigado.png";
import ProductCard from "../components/ProductCard/ProductCard";
import { boxSubscriptionData } from "../data/box-subscription-data";
import { monthlyData } from "../data/monthly-data";
import ProductSwiper from "../components/ProductSwiper/ProductSwiper";
import Section from "../components/Layout/Section/Section";
import YellowButton from "../components/Layout/YellowButton/YellowButton";

const ThankYou = (props) => {
  let unifyData = [...boxSubscriptionData, ...monthlyData];
  unifyData.sort(() => Math.random() - 0.5).length = 3;

  return (
    <>
      <Section border>
        <div className=" grid sm:grid-cols-2 mt-12 mb-8 sm:mb-0 h-full rounded shadow-xl border border-whiteSmoke">
          <div className="flex mx-auto sm:my-14 justify-center items-center flex-col bg-transparent sm:bg-white rounded-full h-52 w-52 md:h-80 md:w-80 lg:h-98 lg:w-98">
            <h3 className="my-5 sm:my-2">Obrigado!</h3>
            <img
              src={ThankYouImg}
              alt="obrigado"
              className="h-28 w-28 sm:h-4/6 sm:w-4/6"
            />
          </div>

          <div className="sm:h-full my-auto flex flex-col justify-center items-center sm:items-start mb-8">
            <h3>Assinatura confirmada!</h3>

            <p className="px-4 sm:px-0 sm:pr-2 lg:w-3/4 xl:w-3/5 text-center sm:text-left my-6">
              Em breve levaremos a melhor produção local até você! Acompanhe no
              e-mail informações sobre a sua cesta e a previsão de entrega.
              <br />
              <br />
              Complemente sua assinatura com itens da nossa feira online e sem custo de frete.
            </p>
            <a href="https://sacolavirtual.com" target="_blank" rel="noreferrer">
              <YellowButton>Ir para a Sacola da Junta</YellowButton>
            </a>
            <p className="px-4 sm:px-0 sm:pr-2 lg:w-3/4 xl:w-3/5 text-center sm:text-left my-6">
              Obrigada por escolher a Junta Local!
            </p>
            <button className="bg-yellow text-lg sm:text-xl w-3/4 xl:w-1/2 font-semibold h-11 rounded shadow focus:shadow-none hover:bg-lightYellow transition duration-500">
              <Link to="/assinatura">Escolher outra assinatura</Link>
            </button>
          </div>
        </div>
      </Section>
      <div className="mt-8 md:my-8 pl-2 md:pl-4 text-center sm:text-left container mx-auto">
        <h3 className="border-b-4 border-yellow inline ">
          Recomendações para você
        </h3>
      </div>
      <div className="xl:hidden">
        <div className="hidden xl:grid sm:grid-cols-2 xl:grid-cols-3 gap-3 xl:gap-7 sm:pt-4">
          {unifyData.map((box) => (
            <ProductCard box={box} key={box.title} />
          ))}
        </div>
        <ProductSwiper data={unifyData} />
      </div>
      <div className="hidden xl:block">
        <Section id="caixas-da-junta" pt={false}>
          <div className="hidden xl:grid sm:grid-cols-2 xl:grid-cols-3 gap-3 xl:gap-7 sm:pt-4">
            {unifyData.map((box) => (
              <ProductCard box={box} key={box.title} />
            ))}
          </div>
          <ProductSwiper data={unifyData} />
        </Section>
      </div>
    </>
  );
};

export default ThankYou;
