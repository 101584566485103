import { createSlice } from "@reduxjs/toolkit";

const initialFairState = {
  nextFairs: [
    {
      startAt: "",
      endAt: "",
      neighborhood: "",
      local: "",
    },
  ],
};

const fairSlice = createSlice({
  name: "fair",
  initialState: initialFairState,
  reducers: {
    setNextFairs(state, action) {
      state = action.payload;
      return state;
    },
  },
});

export const getNextFairs = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      var query = `{
        nextFairs {
          startAt
          endAt
          name
          neighborhood
        }
      }`;

      const response = await fetch(process.env.REACT_APP_BACKEND_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ query: query }),
      });

      const data = await response.json();

      return data.data;
    };

    try {
      const fairResponse = await fetchData();
      dispatch(fairActions.setNextFairs(fairResponse));
    } catch (error) {
      dispatch(fairActions.setNextFairs(initialFairState));
      throw new Error("Could not fetch data: " + error.message);
    }
  };
};

export const fairActions = fairSlice.actions;
export default fairSlice.reducer;
