import { useState } from "react";
import OptionButton from "./OptionButton";

const GroupButton = (props) => {
  const buttonInfo = props.data.buttons;
  const [clickedButton, setClickedButton] = useState(props.defaultButton);

  const bgColorHandler = (e) => {
    if (!props.disabledButton) {
      return
    }
    const currentClicked = e.target.value;

    setClickedButton(Number(currentClicked));
    props.onClick(e);
  };

  return (
    <div className={`${props.externalDiv}`}>
      <h5 className={`items-center ${props.groupHeader}`}>
        {props.data.label}
      </h5>
      <div className={props.internalDiv}>
        {buttonInfo.map((button) => {
          return (
            <OptionButton
              key={button.value}
              title={button.title}
              value={button.value}
              onClick={bgColorHandler}
              clicked={clickedButton}
              disabled={props.disabledButton}
              dynamicStyles={props.button || ""}
            />
          );
        })}
      </div>
    </div>
  );
};
export default GroupButton;
