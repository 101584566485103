const Price = (props) => {
  return (
    <div className="flex justify-center items-center">
      <div className="lg:block">
        <span className="text-base font-extrabold mr-2">Por apenas</span>
        <span className="text-base font-extrabold mr-2 text-yellow">R$</span>
        <span className="text-yellow mr-2">
          <span className="text-5xl font-extrabold">
            {props.price.slice(0, -3)}
          </span>
          <span className="text-xl font-extrabold leading-13 align-top">
            {props.price.slice(-3)}
          </span>
        </span>
      </div>
      <span className="flex flex-col items-start pt-1">
        <span className="text-base font-bold mb-1">/ {props.period}</span>
        <span className="text-base font-bold bg-whiteSmoke px-2 rounded truncate">
          frete grátis
        </span>
      </span>
    </div>
  );
};

export default Price;
