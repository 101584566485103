import Section from "../../Layout/Section/Section";
import Carousel from "../../Carousel/Carousel";
import { CAROUSEL } from "./carouselData";
import Bandeira from "../../../assets/images/svg/Bandeira";

const items = [
  {
    title: "Boa",
    subtitle:
      "O melhor da nossa comunidade, sempre fresco e com a garantia da nossa curadoria.",
  },
  {
    title: "Local",
    subtitle:
      "Aqui você sabe de quem está comprando, pequenos produtores da nossa comunidade.",
  },
  {
    title: "Justa",
    subtitle:
      "Ao comprar direto de quem faz, cada centavo vai para quem importa, e você ainda paga menos.",
  },
];

const AboutTheJunta = () => {
  return (
    <Section id="sobre-nos" pt={true}>
      <div className="flex flex-col items-center lg:grid lg:grid-cols-8 xl:grid-cols-5 content-center gap-6 xl:gap-8 py-4">
        <Carousel
          className=" lg:col-span-6 xl:col-span-3 overflow-hidden max-w-3xl lg:max-w-full"
          type="auto"
          pagination
          gallery={CAROUSEL}
        />
        <div className="flex flex-col justify-between items-center lg:items-start lg:pb-7 lg:col-span-2 h-full">
          <Bandeira />
          {items.map((item) => (
            <div
              key={item.title}
              className="py-2 lg:py-0 text-center lg:text-left"
            >
              <h4 className="border-yellow border-b-4 inline">{item.title}</h4>
              <p className="ax-w-md lg:max-w-full mt-2 lg:mt-4 lg:mb-4">
                {item.subtitle}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default AboutTheJunta;
