import { useState } from "react";

const Manual = (props) => {
  const [selectedImg, setSelectedImg] = useState(0);

  const ImgHandler = (value) => {
    setSelectedImg(value);
  };
  const galleryLength = props.gallery.length;

  return (
    <>
      {props.gallery ? (
        <div className="rounded-xl">
          <div className="mb-4 border border-grey rounded-xl bg-whiteSmoke">
            <img
              className={`${
                props.gallery ? props.gallery[selectedImg].type : "object-cover"
              } mx-auto rounded-xl h-80 md:h-110 xl:h-120 w-full`}
              src={props.gallery ? props.gallery[selectedImg].name : props.src}
              alt="Caixa de produtos"
              role="figure"
            />
          </div>
          <div className="flex justify-center lg:justify-start">
            {props.gallery &&
              props.gallery.map((img, i) => {
                return (
                  <button key={i} onClick={() => ImgHandler(i)}>
                    <div
                      className={`h-20
                      w-20
                      border border-grey
                      rounded-md
                      flex 
                      justify-center 
                      hover:opacity-90
                      transition duration-150 ${
                        i === selectedImg && "bg-whiteSmoke"
                      } ${i === galleryLength - 1 ? "mr-0" : "mr-4"}`}
                    >
                      <img
                        src={img.name}
                        alt="Caixa"
                        className="object-cover rounded-xl"
                      />
                    </div>
                  </button>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="bg-grey h-full lg:col-span-7 xl:col-span-8 rounded-xl"></div>
      )}
    </>
  );
};

export default Manual;
