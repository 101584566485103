import { boxSubscriptionData } from "../data/box-subscription-data";
import { monthlyData } from "../data/monthly-data";

export const selectedBoxHelper = (boxId) => {
  let selectedBoxVar;
  let indexArr = [];
  boxSubscriptionData.forEach((box) =>
    box.productsBySize.forEach((size, indexSize) =>
      size.prices.forEach((price, indexPrice) => {
        if (price.id === boxId) {
          selectedBoxVar = box;
          indexArr = [indexSize, indexPrice];
        }
      })
    )
  );

  monthlyData.forEach((box) => {
    if (box.productsBySize[2].prices[2].id === boxId) {
      selectedBoxVar = box;
      indexArr = [2, 2];
    }
  });

  return { selectedBoxVar, indexArr };
};
