export const spice = [
  {
    id: "ct1",
    category: "Temperos",
    products: [
      {
        id: "tp1",
        type: "Manjericão",
        options: ["Manjericão (1un, aprox. 80g)"],
      },
      {
        id: "tp2",
        type: "Coentro",
        options: ["Coentro (1un, aprox. 80g)"],
      },
      {
        id: "tp3",
        type: "Salsa",
        options: ["Salsa (1un, aprox. 80g)"],
      },
      {
        id: "tp4",
        type: "Cebolinha",
        options: ["Cebolinha (1un, aprox. 80g)"],
      },
      {
        id: "tp5",
        type: "Cheiro Verde",
        options: ["Cheiro Verde (1un, aprox. 80g)"],
      },
      {
        id: "tp6",
        type: "Hortelã",
        options: ["Hortelã (1un, aprox. 50g)"],
      },
      {
        id: "tp7",
        type: "Alho",
        options: ["Alho Poró (1un, aprox. 150g)", "Alho (1un, aprox. 100g)"],
      },
      {
        id: "tp8",
        type: "Cúrcuma",
        options: ["Cúrcuma (1un, aprox. 200g)"],
      },
      {
        id: "tp9",
        type: "Gengibre",
        options: ["Gengibre (1un, aprox. 200g)"],
      },
      {
        id: "tp10",
        type: "Alecrim",
        options: ["Alecrim (1un, aprox. 50g)"],
      },
      {
        id: "tp11",
        type: "Capim Limão",
        options: ["Capim Limão (1un, aprox. 50g)"],
      },
      {
        id: "tp12",
        type: "Cebolete",
        options: ["Cebolete (1un, aprox. 50g)"],
      },
      {
        id: "tp13",
        type: "Louro Verde",
        options: ["Louro Verde (1un, aprox. 50g)"],
      },
      {
        id: "tp14",
        type: "Manjericão",
        options: [
          "Manjericão Anis (1un, aprox. 100g)",
          "Manjericão Limão (1un, aprox. 100g)",
        ],
      },
      {
        id: "tp15",
        type: "Manjerona",
        options: ["Manjerona (1un, aprox. 50g)"],
      },
      {
        id: "tp16",
        type: "Orégano",
        options: ["Orégano (1un, aprox. 50g)"],
      },
      {
        id: "tp17",
        type: "Nirá",
        options: ["Nirá (1un, aprox. 50g)"],
      },
    ],
  },
];
