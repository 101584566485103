import { useState } from "react";
import { deliveryData } from "../data/delivery-data";
import DeliveryInformation from "../components/BoxContent/DeliveryInformation";
import React from "react";
import Section from "../components/Layout/Section/Section";
import InputCep from "../components/InputCep/InputCep";

const Delivery = () => {
  const [cep, setCep] = useState("");

  const SortedData = Object.entries(deliveryData).map((city) => {
    let firstLetter = {};
    Object.keys(city[1])
      .sort((a, b) => a.localeCompare(b))
      .forEach((neighborhood) => {
        const noAccent = neighborhood
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        if (firstLetter[noAccent[0]]) {
          firstLetter[noAccent[0]].push(neighborhood);
        } else {
          firstLetter = {
            ...firstLetter,
            [noAccent[0]]: [neighborhood],
          };
        }
      });
    return {
      [city[0]]: firstLetter,
    };
  });

  return (
    <div>
      <Section pt={true}>
        <div className="text-center sm:text-left py-8">
          <h3>Cidades e Bairros</h3>
          <p className="mt-4">
          Aqui você encontra os locais onde a Junta Local realiza entregas da Sacola da Junta, Pede Junta e Assina Junta.<br/>
          Verifique se a sua cidade e bairro estão dentro do nosso raio de entrega nas listas abaixo. Ou então faça uma busca pelo CEP.<br/> 
          <br/>
          A taxa de entrega da Sacola da Junta está indicada ao lado do nome de cada bairro.  <br/>
          <br/>
          O Pede Junta, nossa entrega rápida, possui uma área de entrega limitada ao Rio de Janeiro e a taxa de entrega varia entre R$8,00 e R$20,00.<br/>
          <br/>
          Não há taxa de entrega no Assina Junta e nem para pedidos complementares pela Sacola da Junta feitos por assinantes, desde que os pedidos sejam feitos para o dia de entrega da caixa escolhida. 
          </p>
        </div>
      </Section>
      <Section bgFullWidth="bg-whiteSmoke">
        <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start mb-10">
          <InputCep
            delivery={true}
            cep={cep}
            onChange={(e) => setCep(e.target.value)}
            pClassName="md:w-72 h-6 flex items-center justify-center mt-4 lg:mt-0 lg:ml-4 text-center lg:text-left"
          />
        </div>
        <div className="pb-8 grid lg:grid-cols-2 gap-8">
          {Object.keys(deliveryData).map((city, index) => (
            <div key={city} className="bg-white rounded shadow-xl h-120">
              <div className=" border-b border-grey rounded-t px-12 py-4">
                <h5 className="text-center sm:text-left">{city}</h5>
              </div>
              <div className="grid grid-cols-2 gap-4 sm:px-12 p-4 h-110 overflow-auto">
                {Object.values(SortedData[index]).map((currentArr) => {
                  const key = Object.keys(currentArr);
                  return key.map((firstLetter) => {
                    return (
                      <div key={city + firstLetter} className="mb-8">
                        <h5 className="col-span-2">{firstLetter}</h5>
                        <div
                          className="border-l-4 border-yellow pl-4 mt-4
                         ml-1"
                        >
                          {currentArr[firstLetter].map((location) => (
                            <p
                              key={`${city}-${location}`}
                              className="leading-none my-4"
                            >
                              {location}: {deliveryData[city][location]}
                            </p>
                          ))}
                        </div>
                      </div>
                    );
                  });
                })}
              </div>
            </div>
          ))}
        </div>
      </Section>
      <div className="pb-8">
        <DeliveryInformation />
      </div>
    </div>
  );
};

export default Delivery;
