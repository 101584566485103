import { Link } from "react-router-dom";
import YellowButton from "../Layout/YellowButton/YellowButton";
import { sendGAEvent } from "../../helpers/SendGAEvent";

const SubscriptionButton = (props) => {
  let propEvent = {
    category: 'cart',
    action: "begin_checkout",
    label: 'SubscriptionButton'
  }
  return (
    <Link to={props.href} onClick={sendGAEvent.bind(this, propEvent)}>
      <YellowButton>Faça sua assinatura</YellowButton>
    </Link>
  );
};

export default SubscriptionButton;
