const Batata = () => {
  return (
    <>
      <svg
        className="hidden sm:inline absolute xl:top-16 -z-10 h-full xl:h-5/6 w-3/5 max-w-2xl xl:max-w-full lg:w-1/2 xl:w-45%"
        viewBox="0 0 640 600"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M238.417 1.8864C494.832 14.2131 678.642 172.165 632.618 407.229C589.896 625.424 325.243 634.831 84.5799 549.841C-104.67 483.007 -259.597 386.691 -238.897 236.59C-182.569 78.8677 -65.9459 -12.7453 238.417 1.8864Z"
          fill="#FBC446"
        />
      </svg>
      <svg
        className="sm:hidden absolute top-16 -left-28 h-3/4 -z-10 transform scale-105"
        viewBox="0 0 881 600"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M479.407 1.88542C735.822 14.2121 919.633 172.164 873.608 407.228C830.886 625.423 566.233 634.83 325.57 549.84C136.32 483.006 -18.607 386.69 2.09282 236.589C58.4214 78.8667 175.044 -12.7463 479.407 1.88542Z"
          fill="#FBC446"
        />
      </svg>
    </>
  );
};

export default Batata;
