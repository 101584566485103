import { HashLink } from "react-router-hash-link";
import YellowButton from "../Layout/YellowButton/YellowButton";

const HashButton = (props) => {
  return (
    <HashLink smooth to={props.to}>
      <YellowButton wfull={props.wfull}>{props.children}</YellowButton>
    </HashLink>
  );
};

export default HashButton;
