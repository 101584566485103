import InputMask from "react-input-mask";
import { useState, useEffect, useCallback } from "react";
import { consultarCep } from "correios-brasil";
import { deliveryData } from "../../data/delivery-data";
import ClipLoader from "react-spinners/ClipLoader";

const InputCep = (props) => {
  const [message, setMessage] = useState("");
  const [cep, setCep] = useState(props.cep);
  const [messageColor, setMessageColor] = useState("");
  const [loading, setLoading] = useState(false);

  const changeCepHandler = (event) => {
    const cepUpdate = event.target.value.replace(/[^0-9]/g, "");
    props.onChange(event);
    setCep(cepUpdate);

    if (cepUpdate.length === 8) {
      cepSearch(cepUpdate);
    } else {
      setMessage("");
      setMessageColor("");
      props.cepResponseHandler &&
        props.cepResponseHandler({
          cep: "",
          localidade: "",
          bairro: "",
          logradouro: "",
        });
    }
  };

  const cepSearch = useCallback(
    (cep) => {
      setLoading(true);
      consultarCep(cep)
        .then((response) => {
          if (props.delivery) {
            if (
              Object.keys(deliveryData).indexOf(response.localidade) !== -1 &&
              Object.keys(deliveryData[response.localidade]).indexOf(response.bairro) !== -1
            ) {
              setMessage("Seu bairro está disponível para entrega!");
              setMessageColor("bg-lightGreen rounded");
              props.cepResponseHandler && props.cepResponseHandler(response);
            } else {
              setMessage(
                "Infelizmente ainda não entregamos no seu bairro... Mas em breve estaremos com você!"
              );
              setMessageColor("text-red");
              props.cepResponseHandler &&
                props.cepResponseHandler({
                  cep: "",
                  localidade: "",
                  bairro: "",
                  logradouro: "",
                });
            }
          } else {
            props.cepResponseHandler && props.cepResponseHandler(response);
          }
          setLoading(false);
        })
        .catch((e) => {
          setMessage("CEP inválido. Tente novamente, por favor.");
          setMessageColor("text-red");
          props.cepResponseHandler &&
            props.cepResponseHandler({
              cep: "",
              localidade: "",
              bairro: "",
              logradouro: "",
            });
          setLoading(false);
        });
    },
    [props]
  );

  useEffect(() => {
    if (cep) {
      cepSearch(cep.replace(/[^0-9]/g, ""));
    }
  }, []);

  return (
    <>
      <div
        className={`flex justify-center items-center border border-grey rounded-xl px-4 h-14 ${props.disabled ? 'bg-disabledGrey' : 'bg-white'}`}
      >
        <InputMask
          id="cep"
          mask="99999-999"
          className="focus:outline-none text-lg w-full"
          type="tel"
          placeholder="Digite seu CEP"
          onChange={changeCepHandler}
          value={cep}
          aria-label="cep-input"
          disabled={props.disabled || false}
        />
        {loading ? (
          <ClipLoader color="#FBC446" loading={loading} size={20} />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#D6D6D6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        )}
      </div>

      <p
        className={`text-sm font-normal italic ${messageColor} ${props.pClassName}`}
        role="status"
      >
        {message}
      </p>
    </>
  );
};

export default InputCep;
