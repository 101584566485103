import Section from "../../Layout/Section/Section";
import Local from "../../../assets/images/svg/Local";
import Pratico from "../../../assets/images/svg/Pratico";
import Beneficio from "../../../assets/images/svg/Beneficio";

const DUMMY_WHYCHOOSEUS = [
  {
    icon: Pratico(),
    title: "PRÁTICO",
    subtitle:
      "Assine em menos de 5 minutos e receba em casa os produtos que mais combinam com você.",
  },
  {
    icon: Local(),
    title: "CHEGA JUNTO!",
    subtitle:
      "Nosso carimbo de qualidade e proximidade com o produtor, fortalecendo nossa comunidade local.",
  },

  {
    icon: Beneficio(),
    title: "BENEFÍCIOS",
    subtitle:
      "Ao assinar você ganha 5% de desconto em compras de itens na Sacola da Junta para complementar sua caixa.",
  },
];

const WhyChooseUs = () => {
  return (
    <div className="mb-20 border-t border-b border-grey">
      <Section pt={true}>
        <div className="flex flex-col items-center sm:grid grid-cols-3 gap-8 text-center justify-items-center">
          {DUMMY_WHYCHOOSEUS.map((response, i) => (
            <div
              className={`flex flex-col items-center col-span-2 sm:col-span-1 max-w-md ${
                i === DUMMY_WHYCHOOSEUS.length - 1 ? "col-start-2" : ""
              }`}
              key={response.title}
            >
              <div className="my-4">{response.icon}</div>
              <h4 className="my-4 font-extrabold">{response.title}</h4>
              <p>{response.subtitle}</p>
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
};

export default WhyChooseUs;
