import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Breadcrumb = (props) => {
  return (
    <div className=" text-grey px-4 truncate text-center mt-8 mb-6 sm:mt-10 sm:mb-8 font-medium text-sm sm:text-base">
      <Link to="/assinatura" className="hover:text-black transition duration-200">
        Página Inicial
      </Link>
      <span className="mx-4">/</span>
      <HashLink
        smooth
        to={`/assinatura#${props.hashpath}`}
        className="hover:text-black transition duration-200"
      >
        {props.category}
      </HashLink>
      <span className="mx-4">/</span>
      <span className="text-yellow underline">{props.title}</span>
    </div>
  );
};

export default Breadcrumb;
