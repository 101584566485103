export const sendGTM = (props, e) => {
  e.preventDefault();
  let dataLayer = window.dataLayer || [];

  dataLayer.push({
    'event': props.action,
    'transactionId': '1234', // obrigatório - transação operacional
    'transactionTotal': props.value, //obrigatório valor total da transação
    'transactionProducts': [{
        'sku': props.id, //  Stock Keeping Unit
        'name': props.name,
        'category': props.category,
        'price': props.value,
        'quantity': 1
    }]
  });
}