export const subsForYouData = [
  {
    title: "Caixas da Junta",
    description:
      "Perfeitas para o dia a dia, verduras, legumes e frutas (sempre sem veneno, em sua maioria orgânicos certificados) e outros itens essenciais, sem abrir mão do frescor e do melhor da produção local.",
    steps: [
      "Selecione a caixa que mais combina com você.",
      "Escolha a periodicidade e o tamanho.",
      "Faça sua assinatura.",
    ],
    buttonId: "#caixas-da-junta",
  },
  {
    title: "Descobertas mensais",
    description:
      "Uma caixa com produtos escolhidos a dedo pela curadoria da Junta Local para que você tenha novas experiências e descobertas todos os meses.",
    steps: [],
    buttonId: "#descobertas-mensais",
  },
];
