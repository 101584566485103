import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import Home from "./pages/Home";
import Box from "./pages/Box";
import { monthlyData } from "./data/monthly-data";
import ScrollToTop from "./helpers/scrollToTop";
import DeliveryForm from "./pages/DeliveryForm";
import PaymentForm from "./pages/PaymentForm";
import ThankYou from "./pages/ThankYou";
import Delivery from "./pages/Delivery";
import LandingPage from "./pages/LandingPage";
import { boxSubscriptionData } from "./data/box-subscription-data";

function App() {
  const BoxesData = [
    { hash: "descobertas-mensais", data: [...monthlyData] },
    { hash: "caixas-da-junta", data: [...boxSubscriptionData] },
  ];

  return (
    <Layout>
      <ScrollToTop />
      <Switch>
        <Route path="/dados-entrega/:boxId">
          <DeliveryForm />
        </Route>
        <Route path="/pagamento/:boxId">
          <PaymentForm />
        </Route>

        {BoxesData.map(
          (boxInfo) =>
            boxInfo.data &&
            boxInfo.data.map((data) => {
              return (
                <Route path={data.href} key={data.href}>
                  <Box box={data} hash={boxInfo.hash} />
                </Route>
              );
            })
        )}
        <Route path="/agradecimento">
          <ThankYou />
        </Route>
        <Route path="/bairros-e-entrega">
          <Delivery />
        </Route>

        <Route path="/assinatura">
          <Home />
        </Route>
        <Route path="/">
          <LandingPage />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
