import { sendGAEvent } from "../../helpers/SendGAEvent";

const ProductCard = (props) => {
  let box = props.box;
  box.action = "view_to_cart";
  let productIndex = 2;
  if (props.box.buttons) {
    productIndex = 0;
  }

  return (
    <div
      href={box.href}
      onClick={sendGAEvent.bind(this, box)}
      className={
        "xl:relative bg-white pb-6 w-full rounded shadow-xl h-full active:bg-whiteSmoke hover:opacity-95 transition duration-300 xl:border border-whiteSmoke"
      }
    >
      <div
        className={`absolute top-0 right-0 bg-white w-32 h-16 sm:h-20 rounded-bl rounded-tr flex flex-col justify-center items-center ${
          props.box.buttons ? "mt-2" : "mt-1"
        }`}
      >
        <div className="flex flex-col items-start">
          <span
            className={`font-bold text-sm ${
              productIndex === 2 ? "hidden" : ""
            } `}
          >
            A partir de
          </span>
          <span className="text-yellow font-extrabold text-3xl">
            {box.available && <span className="text-lg align-bottom">R$ </span>}
            {box.productsBySize[productIndex].prices[productIndex].price.slice(
              0,
              -3
            )}
            <span className="text-lg align-top">
              {box.productsBySize[productIndex].prices[
                productIndex
              ].price.slice(-3)}
            </span>
          </span>
        </div>
      </div>
      <a href={box.available ? box.href : undefined}>
        <img
          src={box.gallery[0].name}
          alt={box.title}
          className="p-3 rounded h-80 object-cover w-full"
        />
      </a>
      <div className="flex flex-col px-6 text-center">
        <a href={box.available ? box.href : undefined}>
          <div className="h-12 mt-2 sm:mt-4 flex flex-col">
            <h4>{box.title}</h4>
            <p className="mt-1 text-base">{box.productQuantity}</p>
          </div>
          <p className="h-20 sm:h-20 mb-8 sm:mb-8 my-4 py-4 text-center">
            {box.shortDescription}
          </p>
          {box.complement && (
            <p className="h-14 sm:h-20 my-8 sm:my- text-center text-xs sm:text-base">
              Esse complemento só está disponível para assinantes das caixas
              Verdinhos e Coloridos Locais, Caixa Básica Local ou Caixa
              Completona Local assinadas com a mesma frequência de entrega.
            </p>
          )}
        </a>

        <div className="h-18 flex flex-col justify-center iten px-6 2xl:px-28 ">
          <a href={box.available ? box.href : undefined}>
            <div
              className={`bottom-9 mt-2 my-3 ${
                !box.available
                  ? "opacity-50 shadow-none cursor-not-allowed"
                  : " bg-yellow hover:bg-lightYellow"
              } bg-yellow transition duration-300 font-bold shadow-lg focus:shadow-none rounded  py-2 px-10 sm:py-3 text-base`}
            >
              <span className="block lg:hidden">
                {box.available ? "Assine" : "Em breve!"}
              </span>
              <span className="lg:block hidden">
                {box.available ? "Faça sua assinatura" : "Em breve!"}
              </span>
            </div>
          </a>

          {!box.complement && !box.discovery && (
            <a href={box.href + "-vegana"}>
              <div
                className={`bottom-9 mb-2 ${
                  !box.available
                    ? "opacity-50 shadow-none cursor-not-allowed"
                    : " bg-grey hover:bg-lightGrey"
                } bg-grey transition duration-300 font-bold shadow-lg focus:shadow-none rounded  py-2 px-10 sm:py-3 text-base`}
              >
                <span className="block lg:hidden">
                  {box.available ? "Opção vegana!" : "Em breve!"}
                </span>
                <span className="lg:block hidden">
                  {box.available ? "Conheça a opção vegana!" : "Em breve!"}
                </span>
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
