import Container from "../Layout/Container/Container";
import aspastop from "../../assets/images/aspastop.png";
import aspasbot from "../../assets/images/aspasbot.png";

const testimonials = [
  {
    key:0,
    name: "Veruska R.",
    text: "Fizemos a assinatura e recebemos produtos incríveis em casa. A vontade de receber mais coisas especiais só aumenta , até porque sempre vamos na feira para conhecer os produtos e produtores. ",
    desc: "Verdinhos e Coloridos Locais ",
  },
  {
    key:1,
    name: "Marcelo S.",
    text: "Ao saber que participo de um esforço coletivo para aproximar o produtor do consumidor final, isso faz um sentido ainda maior no processo da escolha do alimento que nutre a mim e minha família. ",
    desc: "Completona Local ",
  },
  {
    key:2,
    name: "Felipe O. ",
    text: "A minha caixa tem sido entregue regularmente, sem atrasos e sua composição sazonal, dentro da categoria que escolhi, tem sido o melhor de tudo, por me incentivar a descobrir produtos e ser um sinônimo de frescor. ",
    desc: "Verdinhos e Coloridos Locais ",
  },
];

const Testimonials = () => {
  return (
    <Container className="py-8 xl:py-16 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-7">
      {testimonials.map((testimonial) => {
        return (
          <div
            key={testimonial.key}
            className="flex flex-col place-self-start bg-white rounded shadow-xl border border-whiteSmoke"
          >
            <div className="p-4 grow self-center flex flex-row justify-center items-center">
              <img className="self-start w-10 md:w-14" src={aspastop} alt="" />
              <p className=" p-2 text-p sm:text-lg sm:leading-6 text-center">
                {testimonial.text}
              </p>
              <img className="self-end w-10 md:w-14" src={aspasbot} alt="" />
            </div>

            <div className=" bg-whiteSmoke rounded-b flex justify-center ">
              <div className="py-2 text-center">
                <p className="font-bold text-xl ">{testimonial.name}</p>
                <p className="text-sm">Assinante da cesta {testimonial.desc} </p>
              </div>
            </div>
          </div>
        );
      })}
    </Container>
  );
};

export default Testimonials;