import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useState } from "react";
import Logo from "../Logo/Logo";

const Layout = (props) => {
  const [load, setLoad] = useState(true);

  window.onload = function () {
    setLoad(false);
  };

  return (
    <div className="relative">
      {!load && <Header />}
      <div className="pt-10 sm:pt-12 lg:pt-16">{props.children}</div>
      <Footer />
      {load && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center h-screen bg-white">
          <Logo className="animate-bounce h-24" />
        </div>
      )}
    </div>
  );
};

export default Layout;
