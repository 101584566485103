import { createSlice } from '@reduxjs/toolkit';
import CryptoJS from 'crypto-js';


const initialPurchaseState = {
  success: false,
  message: ''
}

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState: initialPurchaseState,
  reducers: {
    updateMessage(state, action) {
      state = {
        ...state,
        ...action.payload
      }
      return state
    }
  }
})

export const createSignature = (customer) => {
  return async (dispatch) => {
    const fetchData = async () => {
      var query = `mutation {
        createSignature(input: { body: {
          name: "${customer.firstName} ${customer.lastName}",
          cpf: "${customer.cpf}",
          email: "${customer.email}",
          tel: "${customer.tel}",
          birth: "${customer.birth}",
          boxName: "${customer.boxName}",
          frequency: "${customer.frequency}",
          size: "${customer.size}",
          value: "${customer.value}",
          deliveryAddress: {
            cep: "${customer.deliveryAddress.cep}",
            city: "${customer.deliveryAddress.city}",
            nbhd: "${customer.deliveryAddress.nbhd}",
            street: "${customer.deliveryAddress.street}",
            number: "${customer.deliveryAddress.number}",
            complement: "${customer.deliveryAddress.complement}"
          },
          billingAddress: {
            cep: "${customer.billingAddress.cep}",
            city: "${customer.billingAddress.city}",
            nbhd: "${customer.billingAddress.nbhd}",
            street: "${customer.billingAddress.street}",
            number: "${customer.billingAddress.number}",
            complement: "${customer.billingAddress.complement}"
          },
          day: "${customer.day}",
          information: "${customer.information}",
          registerDate: "${customer.registerDate}",
          receivingOption: "${customer.receivingOption}",
          returnable: "${customer.returnable}",
          sameAddress: "${customer.sameAddress}",
          cardNumber: "${customer.cardNumber}",
          cardName: "${customer.cardName}",
          cardExpirationDate: "${customer.cardExpirationDate}",
          cvv: "${customer.cvv}"
        } }) {
          success
          message
        }
      }`;

      const message = `${customer.registerDate}${customer.email}${process.env.JUNTA_API_KEY}`;
      const hash = CryptoJS.SHA256(message).toString(CryptoJS.enc.Base64);
     

      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL,
        {
          method: 'POST',
          headers: { "Content-Type": "application/json", "Authorization": `Bearer ${hash}` },
          body: JSON.stringify({ query: query })
        }
      );

      const data = await response.json();
      return data.data;
    };

    try {
      const purchaseResponse = await fetchData();
      dispatch(purchaseActions.updateMessage(purchaseResponse.createSignature));
    } catch {
      dispatch(purchaseActions.updateMessage(initialPurchaseState));
      throw new Error('Could not fetch data');
    }
  };
}

export const purchaseActions = purchaseSlice.actions;
export default purchaseSlice.reducer;
