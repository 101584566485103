export const groupButtonData = [
  {
    id: "size",
    label: "Tamanho",
    buttons: [
      { value: 0, title: "Econômica" },
      { value: 1, title: "Média" },
      { value: 2, title: "Família" },
    ],
  },
  {
    id: "frequency",
    label: "Periodicidade",
    buttons: [
      { value: 0, title: "Semanal", period: "Semana" },
      { value: 1, title: "Quinzenal", period: "Quinzena" },
      { value: 2, title: "Mensal", period: "Mês" },
    ],
  },
  {
    id: "day",
    label: "Dia da entrega",
    buttons: [
      { value: 0, title: "Quarta-feira" },
      { value: 1, title: "Sábado" },
    ],
  },
];

export default groupButtonData;
