import imgCaixaCompletonaLocal from "../assets/images/boxes-subscription/caixa-completona-local.png";
import imgCaixaBasica from "../assets/images/boxes-subscription/caixa-basica.jpg";
import imgVerdinhos from "../assets/images/boxes-subscription/verdinhos-e-coloridos.jpg";
import imgBasicaUm from "../assets/images/basicaUm.png";
import imgBasicaDois from "../assets/images/basicaDois.png";
import imgCompletonaUm from "../assets/images/completonaUm.png";
import imgCompletonaDois from "../assets/images/completonaDois.png";
import imgVerdinhosUm from "../assets/images/verdinhosUm.png";
import imgVerdinhosDois from "../assets/images/verdinhosDois.png";
import kombuchas from "../assets/images/boxes-subscription/kombuchas.webp";
import ovos from "../assets/images/boxes-subscription/ovos.webp";
import cafe from "../assets/images/boxes-subscription/cafe.jpg";
import pao from "../assets/images/boxes-subscription/pao.webp";
import laticinios from "../assets/images/boxes-subscription/laticinios.jpg";
import pescados from "../assets/images/boxes-subscription/pescados.jpg";

export const boxSubscriptionData = [
  {
    id: 0,
    category: "Caixas da Junta",
    complement:false ,
    vegan:false,
    available: true,
    title: "Verdinhos e Coloridos Locais",
    productQuantity: "8 a 20 produtos",
    shortDescription:
      "Caixa com uma seleção de verduras, legumes, frutas, folhas e temperos para garantir comida local, saúde e frescor no seu dia a dia.",
    description:
      "Receba uma caixa com uma seleção de folhas, verduras, legumes e temperos para garantir saúde e frescor no seu dia a dia. A partir do tamanho médio inclui ovos e cogumelos. Tudo local e fresquinho, de acordo com as estações do ano. Damos preferência para orgânicos certificados da nossa comunidade. Bom para você, bom para a natureza.",
    href: "/verdinhos-e-coloridos-locais",
    gallery: [
      { type: "object-cover", name: imgVerdinhos },
      { type: "object-contain", name: imgVerdinhosUm },
      { type: "object-contain", name: imgVerdinhosDois },
    ],
    background: "bg-salmon",
    buttons: true,
    productsBySize: [
      {
        id: "ECO",
        title: "Econômica",
        subtitle: "8 produtos",
        items: ["3 legumes", "2 frutas", "1 folhosa", "1 tempero", "1 cítrico"],
        prices: [
          { id: "2c9380847cec6134017d0f5b942f1d4f", price: "79,90" },
          { id: "2c9380847cec6182017d0f5e1ac71c7e", price: "79,90" },
          { id: "2c9380847f25ef71017f27ee263d0082", price: "79,90" },
        ],
      },
      {
        id: "MED",
        title: "Média",
        subtitle: "14 produtos",
        items: [
          "4 legumes",
          "3 frutas",
          "2 folhosas",
          "2 temperos",
          "1 cítrico",
          "1 cogumelo",
          "1 dz de ovos",
        ],
        prices: [
          { id: "2c9380847cec6130017d0f63eb991d49", price: "139,90" },
          { id: "2c9380847cec6182017d0f64cfc41c94", price: "139,90" },
          { id: "2c9380847f22eeac017f27ed368700c0", price: "139,90" },
        ],
      },
      {
        id: "FAM",
        title: "Família",
        subtitle: "20 produtos",
        items: [
          "5 legumes",
          "4 frutas",
          "3 folhosas",
          "3 temperos",
          "2 cítricos",
          "2 cogumelos",
          "1 dz de ovos",
        ],
        prices: [
          { id: "2c9380847cec6182017d0f6614641c97", price: "199,90" },
          { id: "2c9380847cec6130017d0f66ab091d4f", price: "199,90" },
          { id: "2c9380847f25ef71017f27ec7eee0081", price: "199,90" },
        ],
      },
    ],
  },
  {
    id: 1,
    category: "Caixas da Junta",
    complement:false ,
    vegan:false,
    available: true,
    title: "Caixa Básica Local",
    productQuantity: "10 a 25 produtos",
    shortDescription:
      "Tudo que tem na Caixa Verdinhos e Coloridos Locais + grãos (diferentes tipos de arroz, feijão e outros cereais), farinha e café (a partir do tamanho médio).",
    description:
      "Perfeita para garantir um prato completo e local. Tudo que tem no plano Verdinhos e Coloridos Locais, com o acréscimo de grãos (diferentes tipos de arroz, feijão e outros cereais), farinha e café (a partir do tamanho médio). Um, dois, feijão com arroz!",
    href: "/caixa-basica",

    gallery: [
      { type: "object-cover", name: imgCaixaBasica },
      { type: "object-contain", name: imgBasicaUm },
      { type: "object-contain", name: imgBasicaDois },
    ],
    buttons: true,
    productsBySize: [
      {
        id: "ECO",
        title: "Econômica",
        subtitle: "10 produtos",
        items: [
          "3 legumes",
          "2 frutas",
          "1 folhosa",
          "1 tempero",
          "1 cítrico",
          "1 arroz",
          "1 feijão",
        ],
        prices: [
          { id: "2c9380847cec6130017d0f7b046a1d63", price: "109,90" },
          { id: "2c9380847cec6130017d0f7c3c821d67", price: "109,90" },
          { id: "2c9380847f22eeac017f27eb5d8000be", price: "109,90" },
        ],
      },
      {
        id: "MED",
        title: "Média",
        subtitle: "18 produtos",
        items: [
          "4 legumes",
          "3 frutas",
          "2 folhosas",
          "2 temperos",
          "1 cítrico",
          "1 arroz",
          "1 feijão",
          "1 cogumelo",
          "1 dz de ovos",
          "1 farinha",
          "1 café",
        ],
        prices: [
          { id: "2c9380847cec6130017d0f7daa971d6a", price: "204,90" },
          { id: "2c9380847cec6182017d0f7e68b61cbc", price: "204,90" },
          { id: "2c9380847f1cb580017f27ea542b0263", price: "204,90" },
        ],
      },
      {
        id: "FAM",
        title: "Família",
        subtitle: "25 produtos",
        items: [
          "5 legumes",
          "4 frutas",
          "3 folhosas",
          "3 temperos",
          "2 cítricos",
          "1 arroz",
          "1 feijão",
          "2 cogumelos",
          "1 dz de ovos",
          "1 farinha",
          "1 café",
          "1 açúcar",
        ],
        prices: [
          { id: "2c9380847cec6182017d0f7f662c1cbe", price: "274,90" },
          { id: "2c9380847cec6134017d0f8082e81d82", price: "274,90" },
          { id: "2c9380847f25ef71017f27e97fff0080", price: "274,90" },
        ],
      },
    ],
  },
  {
    id: 2,
    category: "Caixas da Junta",
    complement:false ,
    vegan:false,
    available: true,
    title: "Caixa Completona Local",
    productQuantity: "12 a 29 produtos",
    shortDescription:
      "Tudo que tem na Caixa Básica Local + um pão fresquinho de uma das padarias da nossa comunidade, queijos e outros laticínios.",
    description:
      "Tudo que tem na Caixa Básica, com o acréscimo de um pão fresquinho de uma das padarias da nossa comunidade, queijos e outros laticínios para o seu dia a dia. Almoço, café da manhã e lanche com o carimbo da Junta Local.",
    href: "/caixa-completona-local",

    gallery: [
      { type: "object-cover", name: imgCaixaCompletonaLocal },
      { type: "object-contain", name: imgCompletonaUm },
      { type: "object-contain", name: imgCompletonaDois },
    ],
    buttons: true,
    productsBySize: [
      {
        id: "ECO",
        title: "Econômica",
        subtitle: "12 produtos",
        items: [
          "3 legumes",
          "2 frutas",
          "1 folhosa",
          "1 tempero",
          "1 cítrico",
          "1 arroz",
          "1 feijão",
          "1 pão pequeno",
          "1 laticínio",
        ],
        prices: [
          { id: "2c9380847cec6130017d0f83ac931d6f", price: "159,90" },
          { id: "2c9380847cec6182017d0f8463581cc6", price: "159,90" },
          { id: "2c9380847f1cb580017f27e81ed00260", price: "159,90" },
        ],
      },
      {
        id: "MED",
        title: "Média",
        subtitle: "21 produtos",
        items: [
          "4 legumes",
          "3 frutas",
          "2 folhosas",
          "2 temperos",
          "1 cítrico",
          "1 arroz",
          "1 feijão",
          "1 pão médio",
          "2 laticínios",
          "1 cogumelo",
          "1 dz de ovos",
          "1 farinha",
          "1 café",
        ],
        prices: [
          { id: "2c9380847cec6134017d0f8549651d89", price: "279,90" },
          { id: "2c9380847cec6130017d0f8640c61d74", price: "279,90" },
          { id: "2c9380847f1cb580017f27e72aff025e", price: "279,90" },
        ],
      },
      {
        id: "FAM",
        title: "Família",
        subtitle: "29 produtos",
        items: [
          "5 legumes",
          "4 frutas",
          "3 folhosas",
          "3 temperos",
          "2 cítricos",
          "1 arroz",
          "1 feijão",
          "1 pão grande",
          "3 laticínios",
          "2 cogumelos",
          "1 dz de ovos",
          "1 farinha",
          "1 café",
          "1 açúcar",
        ],
        prices: [
          { id: "2c9380847cec6130017d0f875ddc1d75", price: "379,90" },
          { id: "2c9380847cec6182017d0f8817ab1ccd", price: "379,90" },
          { id: "2c9380847f2249c6017f27e5f7bf00e7", price: "379,90" },
        ],
      },
    ],
  },
  {
    id: 3,
    category: "Caixas da Junta",
    complement:false ,
    vegan:true,
    available: true,
    title: "Verdinhos e Coloridos Locais Vegana",
    productQuantity: "8 a 20 produtos",
    shortDescription:
      "Caixa com uma seleção de verduras, legumes, frutas, folhas e temperos para garantir comida local, saúde e frescor no seu dia a dia.",
    description:
    "Receba uma caixa com uma seleção de folhas, verduras, legumes e temperos para garantir saúde e frescor no seu dia a dia. A partir do tamanho médio inclui cogumelos e uma proteína vegana (como tofu e tempê). Tudo local e fresquinho, de acordo com as estações do ano e sempre vegano. Damos preferência para orgânicos certificados da nossa comunidade. Bom para você, bom para a natureza",
    href: "/verdinhos-e-coloridos-locais-vegana",
    gallery: [
      { type: "object-cover", name: imgVerdinhos },
      { type: "object-contain", name: imgVerdinhosUm },
      { type: "object-contain", name: imgVerdinhosDois },
    ],
    background: "bg-salmon",
    buttons: true,
    productsBySize: [
      {
        id: "ECO",
        title: "Econômica",
        subtitle: "8 produtos",
        items: ["3 legumes", "2 frutas", "1 folhosa", "1 tempero", "1 cítrico"],
        prices: [
          { id: "verdinhos-e-coloridos-locais-vegana-eco-semanal", price: "79,90" },
          { id: "verdinhos-e-coloridos-locais-vegana-eco-quinzenal", price: "79,90" },
          { id: "verdinhos-e-coloridos-locais-vegana-eco-mensal", price: "79,90" },
        ],
      },
      {
        id: "MED",
        title: "Média",
        subtitle: "14 produtos",
        items: [
          "4 legumes",
          "3 frutas",
          "2 folhosas",
          "2 temperos",
          "1 cítrico",
          "1 cogumelo",
          "1 proteína vegana",
        ],
        prices: [
          { id: "verdinhos-e-coloridos-locais-vegana-med-semanal", price: "149,90" },
          { id: "verdinhos-e-coloridos-locais-vegana-med-quinzenal", price: "149,90" },
          { id: "verdinhos-e-coloridos-locais-vegana-med-mensal", price: "149,90" },
        ],
      },
      {
        id: "FAM",
        title: "Família",
        subtitle: "20 produtos",
        items: [
          "5 legumes",
          "4 frutas",
          "3 folhosas",
          "3 temperos",
          "2 cítricos",
          "2 cogumelos",
          "1 proteína vegana",
        ],
        prices: [
          { id: "verdinhos-e-coloridos-locais-vegana-fam-semanal", price: "209,90" },
          { id: "verdinhos-e-coloridos-locais-vegana-fam-quinzenal", price: "209,90" },
          { id: "verdinhos-e-coloridos-locais-vegana-fam-mensal", price: "209,90" },
        ],
      },
    ],
  },
  {
    id: 4,
    category: "Caixas da Junta",
    complement:false ,
    vegan:true,
    available: true,
    title: "Caixa Básica Local Vegana",
    productQuantity: "10 a 25 produtos",
    shortDescription:
      "Tudo que tem na Caixa Verdinhos e Coloridos Locais + grãos (diferentes tipos de arroz, feijão e outros cereais), farinha e café (a partir do tamanho médio).",
    description:
      "Perfeita para garantir um prato completo, local e vegano. Tudo que tem no plano Verdinhos e Coloridos Locais, com o acréscimo de grãos (diferentes tipos de arroz, feijão e outros cereais), farinha e café (a partir do tamanho médio). Um, dois, feijão com arroz!",
    href: "/caixa-basica-vegana",

    gallery: [
      { type: "object-cover", name: imgCaixaBasica },
      { type: "object-contain", name: imgBasicaUm },
      { type: "object-contain", name: imgBasicaDois },
    ],
    buttons: true,
    productsBySize: [
      {
        id: "ECO",
        title: "Econômica",
        subtitle: "10 produtos",
        items: [
          "3 legumes",
          "2 frutas",
          "1 folhosa",
          "1 tempero",
          "1 cítrico",
          "1 arroz",
          "1 feijão",
        ],
        prices: [
          { id: "caixa-basica-vegana-eco-semanal", price: "109,90" },
          { id: "caixa-basica-vegana-eco-quinzenal", price: "109,90" },
          { id: "caixa-basica-vegana-eco-mensal", price: "109,90" },
        ],
      },
      {
        id: "MED",
        title: "Média",
        subtitle: "18 produtos",
        items: [
          "4 legumes",
          "3 frutas",
          "2 folhosas",
          "2 temperos",
          "1 cítrico",
          "1 arroz",
          "1 feijão",
          "1 cogumelo",
          "1 proteína vegana",
          "1 farinha",
          "1 café",
        ],
        prices: [
          { id: "caixa-basica-vegana-med-semanal", price: "214,90" },
          { id: "caixa-basica-vegana-med-quinzenal", price: "214,90" },
          { id: "caixa-basica-vegana-med-mensal", price: "214,90" },
        ],
      },
      {
        id: "FAM",
        title: "Família",
        subtitle: "25 produtos",
        items: [
          "5 legumes",
          "4 frutas",
          "3 folhosas",
          "3 temperos",
          "2 cítricos",
          "1 arroz",
          "1 feijão",
          "2 cogumelos",
          "1 proteína vegana",
          "1 farinha",
          "1 café",
          "1 açúcar",
        ],
        prices: [
          { id: "caixa-basica-vegana-fam-semanal", price: "284,90" },
          { id: "caixa-basica-vegana-fam-quinzenal", price: "284,90" },
          { id: "caixa-basica-vegana-fam-mensal", price: "284,90" },
        ],
      },
    ],
  },
  {
    id: 5,
    category: "Caixas da Junta",
    complement:false ,
    vegan:true,
    available: true,
    title: "Caixa Completona Local Vegana",
    productQuantity: "12 a 29 produtos",
    shortDescription:
      "Tudo que tem na Caixa Básica Local + um pão fresquinho de uma das padarias da nossa comunidade, queijos e outros laticínios veganos.",
    description:
      "Tudo que tem na Caixa Básica, com o acréscimo de um pão fresquinho de uma das padarias da nossa comunidade, queijos e outros laticínios veganos para o seu dia a dia. Almoço, café da manhã e lanche com muita consciência e o carimbo da Junta Local.",
    href: "/caixa-completona-local-vegana",

    gallery: [
      { type: "object-cover", name: imgCaixaCompletonaLocal },
      { type: "object-contain", name: imgCompletonaUm },
      { type: "object-contain", name: imgCompletonaDois },
    ],
    buttons: true,
    productsBySize: [
      {
        id: "ECO",
        title: "Econômica",
        subtitle: "12 produtos",
        items: [
          "3 legumes",
          "2 frutas",
          "1 folhosa",
          "1 tempero",
          "1 cítrico",
          "1 arroz",
          "1 feijão",
          "1 pão pequeno",
          "1 laticínio vegano",
        ],
        prices: [
          { id: "caixa-completona-local-vegana-eco-semanal", price: "174,90" },
          { id: "caixa-completona-local-vegana-eco-quinzenal", price: "174,90" },
          { id: "caixa-completona-local-vegana-eco-mensal", price: "174,90" },
        ],
      },
      {
        id: "MED",
        title: "Média",
        subtitle: "21 produtos",
        items: [
          "4 legumes",
          "3 frutas",
          "2 folhosas",
          "2 temperos",
          "1 cítrico",
          "1 arroz",
          "1 feijão",
          "1 pão médio",
          "2 laticínios veganos",
          "1 cogumelo",
          "1 proteína vegana",
          "1 farinha",
          "1 café",
        ],
        prices: [
          { id: "caixa-completona-local-vegana-med-semanal", price: "309,90" },
          { id: "caixa-completona-local-vegana-med-quinzenal", price: "309,90" },
          { id: "caixa-completona-local-vegana-med-mensal", price: "309,90" },
        ],
      },
      {
        id: "FAM",
        title: "Família",
        subtitle: "29 produtos",
        items: [
          "5 legumes",
          "4 frutas",
          "3 folhosas",
          "3 temperos",
          "2 cítricos",
          "1 arroz",
          "1 feijão",
          "1 pão grande",
          "3 laticínios veganos",
          "2 cogumelos",
          "1 proteína vegana",
          "1 farinha",
          "1 café",
          "1 açúcar",
        ],
        prices: [
          { id: "caixa-completona-local-vegana-fam-semanal", price: "419,90" },
          { id: "caixa-completona-local-vegana-fam-quinzenal", price: "419,90" },
          { id: "caixa-completona-local-vegana-fam-mensal", price: "419,90" },
        ],
      },
    ],
  },
  {
    id: 6,
    category: "Caixas da Junta",
    complement:true,
    vegan:false,
    available: true,
    title: "Complemento de Kombuchás",
    productQuantity: "4 a 12 produtos",
    shortDescription:
      "Complemento de assinatura de kombuchás, bebida probiótica e de gaseificação natural, produzida por fermentadores da nossa comunidade. ",
    description: (
      <p>
        Complemente sua caixa de assinatura e garanta uma semana refrescante e
        imunizada com essa caixa de kombuchás, uma alternativa natural e
        saudável para o seu dia a dia, probiótica e de gaseificação natural,
        produzida por fermentadores da nossa comunidade. <br />
        <br />
        <b> ATENÇÃO! </b> Esse complemento só está disponível para assinantes das caixas Verdinhos e Coloridos Locais, Caixa Básica Local ou Caixa Completona Local assinadas com a mesma frequência de entrega.
      </p>
    ),
    href: "/kombuchas",

    gallery: [{ type: "object-cover", name: kombuchas }],
    buttons: true,
    productsBySize: [
      {
        id: "ECO",
        title: "Econômica",
        subtitle: "",
        items: ["4 unidades"],
        prices: [
          { id: "kombucha-eco-semanal", price: "69,90" },
          { id: "kombucha-eco-quinzenal", price: "69,90" },
          { id: "kombucha-eco-mensal", price: "69,90" },
        ],
      },
      {
        id: "MED",
        title: "Média",
        subtitle: "",
        items: ["8 unidades"],
        prices: [
          { id: "kombucha-med-semanal", price: "139,90" },
          { id: "kombucha-med-quinzenal", price: "139,90" },
          { id: "kombucha-med-mensal", price: "139,90" },
        ],
      },
      {
        id: "FAM",
        title: "Família",
        subtitle: "",
        items: ["12 unidades"],
        prices: [
          { id: "kombucha-fam-semanal", price: "179,90" },
          { id: "kombucha-fam-quinzenal", price: "179,90" },
          { id: "kombucha-fam-mensal", price: "179,90" },
        ],
      },
    ],
  },
  {
    id: 7,
    category: "Caixas da Junta",
    complement:true ,
    vegan:false,
    available: true,
    title: "Complemento de Ovos",
    productQuantity: "1/2 a 2 dúzias",
    shortDescription:
      "Complemento de assinatura de ovos caipiras, sempre de produtores que zelam pela natureza e pelo bem estar animal.",
    description: (
      <p>
        Complemente sua caixa de assinatura e garanta o café da manhã com essa
        caixa de ovos caipiras, sempre de produtores que zelam pela natureza e
        pelo bem estar animal. <br />
        <br />
        <b> ATENÇÃO! </b> Esse complemento só está disponível para assinantes das caixas Verdinhos e Coloridos Locais, Caixa Básica Local ou Caixa Completona Local assinadas com a mesma frequência de entrega.
      </p>
    ),
    href: "/ovos",
    gallery: [{ type: "object-cover", name: ovos }],
    buttons: true,
    productsBySize: [
      {
        id: "ECO",
        title: "Econômica",
        subtitle: "",
        items: ["1/2 dúzia"],
        prices: [
          { id: "ovos-eco-semanal", price: "10,90" },
          { id: "ovos-eco-quinzenal", price: "10,90" },
          { id: "ovos-eco-mensal", price: "10,90" },
        ],
      },
      {
        id: "MED",
        title: "Média",
        subtitle: "",
        items: ["1 dúzia"],
        prices: [
          { id: "ovos-med-semanal", price: "19,90" },
          { id: "ovos-med-quinzenal", price: "19,90" },
          { id: "ovos-med-mensal", price: "19,90" },
        ],
      },
      {
        id: "FAM",
        title: "Família",
        subtitle: "",
        items: ["2 dúzias"],
        prices: [
          { id: "ovos-fam-semanal", price: "39,90" },
          { id: "ovos-fam-quinzenal", price: "39,90" },
          { id: "ovos-fam-mensal", price: "39,90" },
        ],
      },
    ],
  },
   {
    id: 8,
    category: "Caixas da Junta",
    complement:true ,
    vegan:false,
    available: true,
    title: "Complemento de Café",
    productQuantity: "250g a 1kg",
    shortDescription:
      "Complemento de assinatura com cafés especiais variados (Moído ou Grãos), produzidos e/ou torrados pela nossa comunidade de produtores locais.",
    description: (
      <p>
        Nosso complemento de café é prova que cafés bons e locais pertencem ao seu dia a dia também. Complemente sua assinatura com cafés produzidos e/ou torrados pela nossa comunidade de produtores. Opte entre café em grãos ou moído (colocar sua preferência no campo de observação).  <br />
        <br />
        <b> ATENÇÃO! </b> Esse complemento só está disponível para assinantes das caixas Verdinhos e Coloridos Locais, Caixa Básica Local ou Caixa Completona Local assinadas com a mesma frequência de entrega.
      </p>
    ),
    href: "/cafe",
    gallery: [{ type: "object-cover", name: cafe }],
    buttons: true,
    productsBySize: [
      {
        id: "ECO",
        title: "Econômica",
        subtitle: "",
        items: ["250g"],
        prices: [
          { id: "cafe-eco-semanal", price: "34,90" },
          { id: "cafe-eco-quinzenal", price: "34,90" },
          { id: "cafe-eco-mensal", price: "34,90" },
        ],
      },
      {
        id: "MED",
        title: "Média",
        subtitle: "",
        items: ["500g"],
        prices: [
          { id: "cafe-med-semanal", price: "69,90" },
          { id: "cafe-med-quinzenal", price: "69,90" },
          { id: "cafe-med-mensal", price: "69,90" },
        ],
      },
      {
        id: "FAM",
        title: "Família",
        subtitle: "",
        items: ["1kg"],
        prices: [
          { id: "cafe-fam-semanal", price: "112,90" },
          { id: "cafe-fam-quinzenal", price: "112,90" },
          { id: "cafe-fam-mensal", price: "112,90" },
        ],
      },
    ],
  },
  {
    id: 9,
    category: "Caixas da Junta",
    complement:true ,
    vegan:false,
    available: true,
    title: "Complemento de Pão",
    productQuantity: "200g a 1kg",
    shortDescription:
      "Complemento de assinatura com pães de fermentação natural produzidos pela nossa comunidade de padeiros locais.",
    description: (
      <p>
        Complemente sua caixa de assinatura e garanta o cheirinho de pão recém assado para o seu dia a dia com essa caixa de pães de fermentação natural produzidos pela nossa Comunidade de padeiros. <br />
        <br />
        <b> ATENÇÃO! </b> Esse complemento só está disponível para assinantes das caixas Verdinhos e Coloridos Locais, Caixa Básica Local ou Caixa Completona Local assinadas com a mesma frequência de entrega.
      </p>
    ),
    href: "/pao",
    gallery: [{ type: "object-cover", name: pao }],
    buttons: true,
    productsBySize: [
      {
        id: "ECO",
        title: "Econômica",
        subtitle: "",
        items: ["200g a 400g"],
        prices: [
          { id: "pao-eco-semanal", price: "20,90" },
          { id: "pao-eco-quinzenal", price: "20,90" },
          { id: "pao-eco-mensal", price: "20,90" },
        ],
      },
      {
        id: "MED",
        title: "Média",
        subtitle: "",
        items: ["500g"],
        prices: [
          { id: "pao-med-semanal", price: "29,90" },
          { id: "pao-med-quinzenal", price: "29,90" },
          { id: "pao-med-mensal", price: "29,90" },
        ],
      },
      {
        id: "FAM",
        title: "Família",
        subtitle: "",
        items: ["800g a 1kg"],
        prices: [
          { id: "pao-fam-semanal", price: "44,90" },
          { id: "pao-fam-quinzenal", price: "44,90" },
          { id: "pao-fam-mensal", price: "44,90" },
        ],
      },
    ],
  },

  {
    id: 10,
    category: "Caixas da Junta",
    complement:true ,
    vegan:false,
    available: true,
    title: "Complemento de Laticínios",
    productQuantity: "1 a 4 produtos",
    shortDescription:
      "Complemente sua caixa de assinatura com o melhor dos laticínios locais para o seu dia-a-dia.",
    description: (
      <p>
        Complemente sua caixa de assinatura com o melhor dos laticínios locais para o seu dia-a-dia. <br />
        <br />
        <b> ATENÇÃO! </b> Esse complemento só está disponível para assinantes das caixas Verdinhos e Coloridos Locais, Caixa Básica Local ou Caixa Completona Local assinadas com a mesma frequência de entrega.
      </p>
    ),
    href: "/laticinios",
    gallery: [{ type: "object-cover", name: laticinios }],
    buttons: true,
    productsBySize: [
      {
        id: "ECO",
        title: "Econômica",
        subtitle:"",
        items: ["1un"],
        prices: [
          { id: "laticinio-eco-semanal", price: "34,90" },
          { id: "laticinio-eco-quinzenal", price: "34,90" },
          { id: "laticinio-eco-mensal", price: "34,90" },
        ],
      },
      {
        id: "MED",
        title: "Média",
        subtitle:"",
        items: ["2un"],
        prices: [
          { id: "laticinio-med-semanal", price: "59,90" },
          { id: "laticinio-med-quinzenal", price: "59,90" },
          { id: "laticinio-med-mensal", price: "59,90" },
        ],
      },
      {
        id: "FAM",
        title: "Família",
        subtitle:"",
        items: ["4un"],
        prices: [
          { id: "laticinio-fam-semanal", price: "104,90" },
          { id: "laticinio-fam-quinzenal", price: "104,90" },
          { id: "laticinio-fam-mensal", price: "104,90" },
        ],
      },
    ],
  },

  {
    id: 11,
    category: "Caixas da Junta",
    complement:true ,
    vegan:false,
    available: true,
    title: "Complemento de Pescados",
    productQuantity: "500g a 2kg",
    shortDescription:
      "Complemente sua caixa de assinatura com uma seleção de pescados, sempre de produtores que zelam pela natureza e pelo bem estar animal.",
    description: (
      <p>
        Complemente sua caixa de assinatura com uma seleção de pescados, sempre de origem conhecida e favorecendo a pesca artesanal ou aquicultura sustentável.<br />
        <br />
        <b> ATENÇÃO! </b> Esse complemento só está disponível para assinantes das caixas Verdinhos e Coloridos Locais, Caixa Básica Local ou Caixa Completona Local assinadas com a mesma frequência de entrega.
      </p>
    ),
    href: "/pescados",
    gallery: [{type: "object-cover", name: pescados }],
    buttons: true,
    productsBySize: [
      {
        id: "ECO",
        title: "Econômica",
        subtitle: "",
        items: ["500g a 600g"],
        prices: [
          { id: "pescados-eco-semanal", price: "59,90" },
          { id: "pescados-eco-quinzenal", price: "59,90" },
          { id: "pescados-eco-mensal", price: "59,90" },
        ],
      },
      {
        id: "MED",
        title: "Média",
        subtitle: "",
        items: ["1kg a 1,2kg"],
        prices: [
          { id: "pescados-med-semanal", price: "109,90" },
          { id: "pescados-med-quinzenal", price: "109,90" },
          { id: "pescados-med-mensal", price: "109,90" },
        ],
      },
      {
        id: "FAM",
        title: "Família",
        subtitle: "",
        items: ["1,8kg a 2kg"],
        prices: [
          { id: "pescados-fam-semanal", price: "169,90" },
          { id: "pescados-fam-quinzenal", price: "169,90" },
          { id: "pescados-fam-mensal", price: "169,90" },
        ],
      },
    ],
  },
];
